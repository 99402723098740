import {
  Button,
  Divider,
  Flex,
  Input,
  Modal,
  Popconfirm,
  Progress,
  Select,
  Upload,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./modal.css";
import { ReactComponent as UploadIcon } from "../../assets/images/upload.svg";
import { ReactComponent as Image } from "../../assets/images/placeholder.svg";
import NotificationInstance from "../../services/notificationServices";
import { CloseOutlined } from "@ant-design/icons";

import {
  UploadFile,
  cancelToken,
  createFileRecord,
  getFileUrl,
  updateProgress,
} from "../../api/adminService";
import axios from "axios";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const UploadFileModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  journeyType,
  VideoIndex,
  section,
  milestoneIndex,
  videoDetail,
  handleDelete,
  handleTaskUpdation,
}) => {
  const [file, setFile] = useState({
    file: null,
    updated: false,
  });
  const [previewImage, setPreviewImage] = useState();
  const [progress, setProgress] = useState("");
  const [uploading, setUploading] = useState(false);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("IMAGE");
  const [extension, setExtension] = useState();
  const [videoData, setvideoData] = useState();
  const [loading, setloading] = useState(false);
  const [acceptExtensions, setAcceptExtensions] = useState(".png,.jpg,.jpeg");
  const [fileID, setfileID] = useState();
  const cancelTokens = useRef({});
  const lastApiCallTimeRef = useRef(Date.now());

  const getAcceptedFileTypes = () => {
    switch (type) {
      case "IMAGE":
        return /image\/(png|jpg|jpeg)/;
      case "PPT":
        return /application\/(vnd.ms-powerpoint|vnd.openxmlformats-officedocument.presentationml.presentation)/;
      case "PDF":
        return /application\/pdf/;
      case "DOC":
        return /application\/(msword|vnd.openxmlformats-officedocument.wordprocessingml.document)/;
      default:
        return /.*/; // Accept all file types by default
    }
  };
  const getError = () => {
    switch (type) {
      case "IMAGE":
        return "Please upload only PNG, JPG, or JPEG files!";
      case "PPT":
        return "Please upload only PPT files!";
      case "PDF":
        return "Please upload only PDF files!";
      case "DOC":
        return "Please upload only DOC files!";
      default:
        return /.*/; // Accept all file types by default
    }
  };

  const handleChange = async (info) => {
    if (!info.file.type.match(getAcceptedFileTypes())) {
      return;
    }
    const extension = info?.file?.name?.slice(
      info?.file?.name?.lastIndexOf(".") + 1
    );
    setExtension(`.${extension}`);
    setFile({ file: info.file, updated: true });
    setPreviewImage(Image);
  };

  const customRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const beforeUpload = (file) => {
    const isAccepted = file.type.match(getAcceptedFileTypes());
    if (!isAccepted) {
      NotificationInstance.warning({
        message: getError(),
      });
      return false;
    }

    return true;
  };
  const onSubmit = () => {
    if (file.file) {
      if (file.updated) {
        if (!title) {
          NotificationInstance.warning({
            message: "Please enter a title",
          });
          return;
        }
        const data = {
          fileType: type,
          title: title,
          originalFileName: file.file.name,
          mimeType: file.file.type,
          journeyType: journeyType,
        };
        setloading(true);
        createFileRecord(data)
          .then((res) => {
            if (res.data._id) {
              const fileData = new FormData();
              fileData.append("file", file.file.originFileObj);
              setfileID(res.data._id);
              UploadFile(res?.data?._id)
                .then((response) => {
                  setUploading(true);
                  handleUpload(response.data, res.data._id);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => console.log(err));
      } else {
        handleCancel();
      }
    } else {
      NotificationInstance.warning({
        message: "Please upload a file!",
      });
    }
  };

  const handleUpload = async (data, id) => {
    const { signature, expiration, blobName, containerName, accountName } =
      data;
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokens.current[id] = cancelTokenSource;
    const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobName}?${signature}`;
    try {
      console.log(file.file);
      const response = await axios.put(url, file.file.originFileObj, {
        headers: {
          "x-ms-blob-type": "BlockBlob",
          "Content-Type": file.file.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
          const now = Date.now();
          if (now - lastApiCallTimeRef.current >= 5000) {
            updateNewProgress("PENDING", percentCompleted, id);
            lastApiCallTimeRef.current = now; // Update the ref directly
          }
        },
        cancelToken: cancelTokenSource.token,
      });

      if (response.status === 201) {
        console.log("File uploaded successfully");
        updateNewProgress("SUCCESS", 100, id);
      } else {
        console.error(
          "Failed to upload file:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      updateNewProgress("FAILED", progress, id);
      console.error("Error uploading file:", error);
    }
  };
  const updateNewProgress = (state, progress, id) => {
    updateProgress(id, {
      status: state,
      progress: progress,
    })
      .then((res) => {
        if (state === "SUCCESS") {
          reset();
          if (videoDetail.task !== false) {
            handleTaskUpdation(id, section, milestoneIndex, videoDetail.task);
          } else {
            handleOk(id, section, milestoneIndex, VideoIndex);
          }
          handleCancel();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const formatSize = (size) => {
    if (size < 1024) {
      return `${size}B`;
    } else if (size < 1024 * 1024) {
      return `${Math.floor(size / 1024)}Kb`;
    } else {
      return `${Math.floor(size / (1024 * 1024))}Mb`;
    }
  };
  const onModalClose = () => {
    if (!loading) {
      reset();
      handleCancel();
    }
  };
  const onPopupClose = () => {
    cancelUpload(fileID);
    updateNewProgress("FAILED", progress, fileID);
    cancelToken();
    reset();
    handleCancel();
  };
  const onChange = (e) => {
    setTitle(e.target.value);
  };
  const onSelect = (val) => {
    if (val === "IMAGE") {
      setAcceptExtensions(".png,.jpg,.jpeg");
    } else if (val === "PPT") {
      setAcceptExtensions(".ppt,.pptx");
    } else if (val === "PDF") {
      setAcceptExtensions(".pdf");
    } else {
      setAcceptExtensions(".doc,.docx");
    }
    setType(val);
  };
  const cancelUpload = (id) => {
    if (cancelTokens.current[id]) {
      cancelTokens.current[id].cancel("Upload canceled by the user.");
      delete cancelTokens.current[id];
    }
  };

  const reset = () => {
    setFile({ file: null, updated: false });
    setPreviewImage();
    setloading(false);
    setUploading(false);
    setTitle("");
    setType("IMAGE");
    setAcceptExtensions(".png,.jpg,.jpeg");
    setProgress("");
  };

  useEffect(() => {
    if (videoDetail.index && isModalOpen) {
      setloading(true);
      getFileUrl([videoDetail.index])
        .then((res) => {
          if (res.data.length > 0) {
            setvideoData(res.data[0]);
            setloading(false);
            setTitle(res.data[0]?.title);
            setType(res.data[0]?.fileType);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setTitle("");
    }
  }, [videoDetail?.index, isModalOpen]);
  return (
    <Modal
      centered
      maskClosable={false}
      width={videoDetail?.index ? 700 : 450}
      title="Upload File"
      open={isModalOpen}
      onCancel={onModalClose}
      closeIcon={
        loading ? (
          <Popconfirm
            description={
              <span>
                File upload is in progress.
                <br /> Are you sure you want to leave?
              </span>
            }
            okText="Yes"
            cancelText="No"
            onConfirm={onPopupClose}
          >
            <CloseOutlined />
          </Popconfirm>
        ) : (
          <CloseOutlined />
        )
      }
      footer={() =>
        videoDetail?.index ? (
          <div className="action-buttons delete">
            <Button
              className="discard-button"
              onClick={() =>
                handleDelete(
                  videoDetail?.index,
                  section,
                  milestoneIndex,
                  VideoIndex,
                  videoDetail?.task !== false ? true : false,
                  videoDetail?.task
                )
              }
            >
              Delete
            </Button>
          </div>
        ) : (
          <div className="action-buttons">
            <Button disabled={loading} type="primary" onClick={onSubmit}>
              Upload
            </Button>
            <Button
              disabled={loading}
              className="cancel-button"
              onClick={onModalClose}
            >
              Cancel
            </Button>
          </div>
        )
      }
    >
      {uploading ? (
        <div className="thumbnail-upload">
          <Image />
          {file?.file?.name && (
            <span className="image-name">{file?.file?.name}</span>
          )}
          <Progress
            showInfo={false}
            strokeColor={"#262528"}
            percent={progress}
          />
        </div>
      ) : loading ? (
        <div className="thumbnail-upload">
          <div className="video-loader">
            <div className="loader"></div>
          </div>
        </div>
      ) : (
        <div className="thumbnail-upload">
          {previewImage ? (
            <>
              <Image />
              {file?.file?.name && (
                <span className="image-name">{file?.file?.name}</span>
              )}
              {file?.image?.size && (
                <span className="image-size">
                  {formatSize(file?.file?.size)}
                </span>
              )}
            </>
          ) : videoDetail?.index ? (
            videoData?.fileType === "IMAGE" ? (
              <Flex align="center" justify="center">
                <img
                  src={videoData?.url}
                  alt=""
                  style={{ width: "100%", height: "auto" }}
                />
              </Flex>
            ) : videoData?.fileType === "PPT" ||
              videoData?.fileType === "DOC" ? (
              <>
                <DocViewer
                  style={{ width: "100%", height: 300 }}
                  documents={[
                    {
                      uri: videoData?.url,
                      fileType: videoData?.fileType === "PPT" ? "ppt" : "docx",
                    },
                  ]}
                  theme={{
                    primary: "#000000",
                    secondary: "#000000",
                    tertiary: "#000000",
                    text_primary: "#000000",
                    text_secondary: "#000000",
                    text_tertiary: "#000000",
                    disableThemeScrollbar: true,
                  }}
                  config={{
                    header: {
                      disableHeader: true,
                    },
                  }}
                  pluginRenderers={DocViewerRenderers}
                />
              </>
            ) : (
              <>
                <iframe
                  style={{ width: "100%", height: 300 }}
                  src={videoData?.url}
                  frameborder="0"
                ></iframe>
              </>
            )
          ) : (
            <>
              <UploadIcon />
              <span className="browse">Browse and Upload a File</span>
              {title === "IMAGE" && (
                <span className="supported-files">JPEG or PNG formats</span>
              )}
            </>
          )}
          {!videoDetail.index && (
            <Upload
              listType="picture"
              maxCount={1}
              multiple={false}
              onChange={handleChange}
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              showUploadList={false}
              accept={acceptExtensions}
            >
              <Button>
                {file.file || videoDetail.index ? "Change FIle" : "Browse File"}
              </Button>
            </Upload>
          )}
          <Input
            readOnly={videoDetail?.index}
            value={title}
            placeholder="Enter a title"
            onChange={onChange}
          />
          <Select
            disabled={videoDetail?.index}
            style={{ width: "100%", height: 40 }}
            value={type}
            onSelect={onSelect}
            options={[
              { value: "IMAGE", label: "Image" },
              { value: "PPT", label: "PPT" },
              { value: "PDF", label: "PDF" },
              { value: "DOC", label: "Docs" },
            ]}
          />
        </div>
      )}
    </Modal>
  );
};

export default UploadFileModal;
