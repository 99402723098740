import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Flex,
  Row,
  Skeleton,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import AddAvatarModal from "../../components/modals/addNewAvatar";
import {
  deleteMyAvatarsById,
  getMyAvatars,
  updateMyAvatarsById,
} from "../../api/adminService";
import Meta from "antd/es/card/Meta";
import {
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
  MoreOutlined,
  ArrowRightOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { useOutletContext } from "react-router-dom";
import { ReactComponent as AvatarIcon } from "../../assets/images/avatar.svg";
import DeleteConfirmModal from "../../components/modals/deleteConfirmModal";
import NotificationInstance from "../../services/notificationServices";
import { endAt, limitToLast, onChildAdded, ref } from "firebase/database";
import { dataBase } from "../../components/firebase/firebase";
import { query } from "firebase/firestore";
import { timeCreatedOn } from "../../utils/format";

const MyAvatars = () => {
  const [avatars, setAvatars] = useState();
  const [avatarsLoading, setAvatarsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, , setDisplayName, , userDetails] = useOutletContext();
  const [avatarId, setAvatarId] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updating, setIsUpdating] = useState(false);

  useEffect(() => {
    setAvatarsLoading(true);
    setDisplayName(["Avatars"]);
    getMyAvatars()
      .then((res) => {
        setAvatarsLoading(false);
        setAvatars(res.data);
      })
      .catch((err) => {
        setAvatarsLoading(false);
      });
  }, []);

  const handleUpdation = (avatarId, data) => {
    setIsUpdating(avatarId);
    updateMyAvatarsById(avatarId, data)
      .then((res) => {
        const updatedAvatars = avatars.map((avatar) =>
          avatar?._id === avatarId
            ? { ...avatar, isDefault: data?.isDefault, name: data?.name }
            : {
                ...avatar,
                isDefault: data?.isDefault ? false : avatar?.isDefault,
              }
        );
        setAvatars(updatedAvatars);
        setAvatarId();
        setIsUpdating();
        NotificationInstance.success({
          message: "Successfully updated avatar",
        });
      })
      .catch((err) => {
        setIsUpdating(avatarId);
      });
  };

  const handleDelete = () => {
    setIsUpdating(avatarId);
    setIsDeleteModalOpen(false);
    deleteMyAvatarsById(avatarId)
      .then((res) => {
        const updatedAvatars = avatars.filter(
          (avatar) => avatar._id !== avatarId
        );
        setAvatars(updatedAvatars);
        setAvatarId();
        setIsUpdating();
        NotificationInstance.success({
          message: "Successfully deleted avatar",
        });
      })
      .catch((err) => {
        setIsUpdating();
      });
  };

  const handleCreate = (data) => {
    setAvatars((prev) => [data, ...prev]);
  };

  const handleAvatarUpdate = (newData) => {
    setAvatars((prevAvatars) =>
      prevAvatars?.map(
        (avatar) =>
          avatar._id === newData.avatarId // Find the avatar by `avatarId`
            ? { ...avatar, ...newData } // Update the avatar's properties
            : avatar // Keep the other avatars unchanged
      )
    );
  };

  useEffect(() => {
    let unsubscribe;
    console.log(userDetails);

    if (userDetails) {
      const channelRef = query(
        ref(dataBase, `avatar/${userDetails?._id}`),
        endAt,
        limitToLast(1)
      );

      unsubscribe = onChildAdded(channelRef, (snapshot) => {
        const message = snapshot.val();
        console.log(message);

        if (message.status === "COMPLETED" || message.status === "FAILED") {
          handleAvatarUpdate(message);
        }
      });
    }

    return () => {
      if (unsubscribe) {
        // Check if unsubscribe is defined before calling
        unsubscribe(); // Call unsubscribe function
      }
    };
  }, [userDetails]);

  return (
    <>
      <Row gutter={[20, 0]} className="avatar-container">
        {isModalOpen && (
          <AddAvatarModal
            isModalOpen={isModalOpen}
            handleCancel={() => {
              setIsModalOpen(false);
              setAvatarId();
            }}
            avatarId={avatarId}
            handleOk={handleCreate}
            handleUpdation={handleUpdation}
          />
        )}
        <DeleteConfirmModal
          isModalOpen={isDeleteModalOpen}
          handleCancel={() => setIsDeleteModalOpen(false)}
          handleOk={handleDelete}
          type={"Avatar"}
        />
        <Col
          xl={{ flex: "25%" }}
          xxl={{ flex: "20%" }}
          className="avatar-list-wrapper"
        >
          <Flex
            vertical
            gap={10}
            align="center"
            justify="center"
            className="add-avatar"
            onClick={() => setIsModalOpen(true)}
          >
            <PlusOutlined />
            <span className="add-new-avatar"> Add New Avatar</span>
          </Flex>
        </Col>
        {avatarsLoading
          ? Array.from({ length: 4 }).map((_, index) => (
              <Col
                xl={{ flex: "25%" }}
                xxl={{ flex: "20%" }}
                className="avatar-card-container avatar-list-wrapper"
              >
                <Flex vertical gap={0} className="avatar-skeleton-container">
                  <Skeleton.Image
                    active
                    style={{ width: "100%", height: 200 }}
                  />
                  <Skeleton
                    active
                    title={false}
                    paragraph={{
                      rows: 3,
                    }}
                  />
                </Flex>
              </Col>
            ))
          : avatars?.map((item, index) => (
              <Col
                xl={{ flex: "25%" }}
                xxl={{ flex: "20%" }}
                className="avatar-list-wrapper"
              >
                <Card hoverable className="avatar-card-container">
                  {updating === item?._id && (
                    <div className="avatar-overlay"></div>
                  )}
                  <div
                    onClick={() => {
                      setAvatarId(item?._id);
                      setIsModalOpen(true);
                    }}
                  >
                    {item?.status === "PROCESSING" ? (
                      <Flex
                        className="avatar-thumbnail"
                        align="center"
                        justify="center"
                        vertical
                        gap={10}
                      >
                        Creating Avatar...
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{ fontSize: 24, color: "black" }}
                              spin
                            />
                          }
                        />
                      </Flex>
                    ) : item?.status === "FAILED" ? (
                      <Flex
                        className="avatar-thumbnail"
                        align="center"
                        justify="center"
                        vertical
                        gap={10}
                      >
                        <h3>Avatar Creation failed!!</h3>
                      </Flex>
                    ) : (
                      <AvatarIcon className="avatar-thumbnail" />
                    )}

                    <Flex vertical gap={0} className="avatar-detail-container">
                      <Flex gap={10} vertical className="avatar-details">
                        <Tooltip title={item.name} placement="topLeft">
                          <Meta title={item.name} />
                        </Tooltip>
                        <span className="avatar-date">
                          {timeCreatedOn(item?.createdAt)}
                        </span>
                      </Flex>
                      {item?.status !== "FAILED" && <Divider />}
                      {item?.status === "COMPLETED" ? (
                        <Flex
                          gap={10}
                          className="avatar-details"
                          justify="flex-end"
                        >
                          <Button
                            type="text"
                            onClick={() => {
                              setAvatarId(item?._id);
                              setIsModalOpen(true);
                            }}
                          >
                            View <ArrowRightOutlined />
                          </Button>
                        </Flex>
                      ) : item?.status === "FAILED" ? (
                        <></>
                      ) : (
                        <Flex className="avatar-details">
                          <Button type="text" block loading={true}>
                            Processing...
                          </Button>
                        </Flex>
                      )}
                    </Flex>
                  </div>

                  <>
                    {item.isDefault && (
                      <Tag className="default-avatar" color="red">
                        Default
                      </Tag>
                    )}
                    <Dropdown
                      align={"left"}
                      className="more-items"
                      overlayStyle={{ width: 200 }}
                      menu={{
                        items: [
                          {
                            key: "1",
                            label: (
                              <Flex
                                onClick={() => {
                                  setAvatarId(item?._id);
                                  setIsDeleteModalOpen(true);
                                }}
                                align="center"
                                justify="flex-start"
                                gap={10}
                              >
                                <DeleteOutlined />
                                <span>Delete</span>
                              </Flex>
                            ),
                          },
                          item?.status === "COMPLETED" &&
                            !item.isDefault && {
                              key: "2",
                              label: (
                                <Flex
                                  onClick={() => {
                                    handleUpdation(item?._id, {
                                      isDefault: true,
                                      name: item?.name,
                                    });
                                  }}
                                  gap={10}
                                  align="center"
                                  justify="flex-start"
                                >
                                  <PlusCircleOutlined />
                                  <span>Set as default</span>
                                </Flex>
                              ),
                            },
                        ],
                      }}
                      placement="bottom"
                      arrow={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      <button className="more-options">
                        <MoreOutlined />
                      </button>
                    </Dropdown>
                  </>
                </Card>
              </Col>
            ))}
      </Row>
    </>
  );
};

export default MyAvatars;
