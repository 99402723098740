import React, { useEffect, useRef, useState } from "react";
import "./lessonAudio.css";
import { Button, Col, Flex, Input, Popconfirm, Row, Tooltip } from "antd";
import { ReloadOutlined, InfoCircleOutlined } from "@ant-design/icons";

import ReactAudioPlayer from "react-audio-player";
import { LoadingOutlined } from "@ant-design/icons";

import NotificationInstance from "../../../services/notificationServices";
import {
  generateUseCaseAudio,
  getMyAvatars,
  updateUseCaseAudio,
} from "../../../api/adminService";
import { scriptUpdateStages, videoStages } from "../../../utils/format";
import VoiceSettings from "../../modals/voiceSettings";
import { courseStageTypes } from "../../../utils/constant";
import AudioProfileModal from "../../modals/audioProfilesModal";
import AvatarProfileModal from "../../modals/avatarProfileModal";

const LessonAudio = ({
  chunks,
  setChunks,
  videoId,
  setVideoId,
  voiceId,
  title,
  courseId,
  lessonId,
  setcurrentStatus,
  currentStatus,
  addVideoId,
  lesson,
  setContentStep,
  voiceSetting,
  setVoiceSetting,
  setSelectedAvatar,
  model,
  selectedAudioProfile,
  setSelectedAudioProfile,
  audioProfiles,
  voicePreview,
  setVoicePreview,
  setVoiceSettings,
  voiceSettings,
}) => {
  const { TextArea } = Input;
  const [audioUrls, setAudioUrls] = useState([]);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [playingChunkIndex, setPlayingChunkIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState();
  const [updateItem, setupdateItem] = useState();
  const [audioProfileModal, setAudioProfileModal] = useState(false);
  const [avatarModalVisible, setAvatarModalVisible] = useState(false);
  const [updatePreview, setUpdatePreview] = useState(false);
  let currentVideoId = "";
  const audioRefs = useRef([]);

  const handlePlay = (index) => {
    // Pause the previous audio if there was one playing
    if (playingChunkIndex !== null && playingChunkIndex !== index) {
      const previousAudio = audioRefs.current[playingChunkIndex];
      if (previousAudio) {
        previousAudio.audioEl.current.pause();
      }
    }
    setPlayingChunkIndex(index); // Update the current playing index
  };

  const playNext = (current, next) => {
    if (audioUrls[next] === null) {
      return;
    }
    if (current === chunks.length - 1) {
      audioRefs.current[current].audioEl.current.pause();
      setCurrentAudio(null);
      setPlayingChunkIndex(null);
    } else if (current < chunks.length - 1) {
      // audioRefs.current[current].audioEl.current.pause();
      audioRefs.current[next]?.audioEl?.current?.play();
      setPlayingChunkIndex(next);
      setCurrentAudio(next);
      if (audioRefs.current[next].audioEl) {
        audioRefs.current[next].audioEl.current.onended = () => {
          playNext(next, next + 1);
        };
      }
    } else if (playingChunkIndex === chunks.length - 1) {
      audioRefs.current[playingChunkIndex].audioEl.current.pause();
      setPlayingChunkIndex(0);
      audioRefs.current[0].audioEl.current.play();
      setCurrentAudio(0);
      audioRefs.current[0].audioEl.current.onended = () => {
        playNext(0, 1);
      };
    }
  };

  const handleSentenceChange = async (index, e) => {
    if (chunks[index]) {
      const updatedScriptParts = chunks.map((part, i) =>
        i === index ? { ...part, text: e.target.value } : part
      );

      setChunks(updatedScriptParts);
    }
  };

  const handleRefresh = async (index, settings) => {
    if (chunks[index]) {
      const text = chunks[index].text;

      if (!text || text.trim() === "") {
        NotificationInstance.error({
          message: "Please enter the audio text",
        });
        return;
      }
      const updatedScriptParts = chunks.map((part, i) =>
        i === index ? { ...part, url: null, voiceSettings: settings } : part
      );
      setChunks(updatedScriptParts);
      const data = {
        audioData: {
          text: updatedScriptParts[index]?.text,
        },
        voiceSettings: settings,
        audioId: updatedScriptParts[index]?._id,
      };
      updateUseCaseAudio(lessonId, data, selectedAudioProfile)
        .then((res) => {
          setcurrentStatus("AUDIO_GENERATION_INPROGRESS");
        })
        .catch((err) => {});
    }
  };

  const handleUpdateAllAudios = async (voicePreview, settings, regenerate) => {
    // Check if any chunk has empty text or text that contains only whitespace
    const invalidChunk = chunks.find(
      (part) => !part.text || part.text.trim() === ""
    );

    if (invalidChunk) {
      // Show error if any chunk has invalid text
      NotificationInstance.error({
        message:
          "Please enter the audio text for all sections before updating.",
      });
      return; // Stop execution if there's an invalid text
    }

    // Proceed if all chunks have valid text
    const updatedScriptParts = chunks.map((part) => ({
      ...part,
      url: null,
    }));

    setChunks(updatedScriptParts);

    const data = {
      voiceSettings: settings,
      voiceId: voiceSetting?.voiceId,
    };

    generateUseCaseAudio(
      lessonId,
      selectedAudioProfile,
      voicePreview,
      data,
      regenerate
    )
      .then((res) => {
        setVoiceSetting((prev) => ({
          settings: settings,
          voiceId: prev?.voiceId,
        }));
        setVoicePreview(voicePreview);
        setUpdatePreview(false);
        setcurrentStatus("AUDIO_GENERATION_INPROGRESS");
      })
      .catch((err) => {
        setUpdatePreview(false);
        console.error(err); // Handle error as needed
      });
  };

  const updateAudio = () => {
    const updatedScriptParts = chunks.map((part, i) => ({
      ...part,
      url: "",
    }));
    setChunks(updatedScriptParts);
    setcurrentStatus("LESSON_SCRIPT_SUBMITTED");
  };

  const updateAudios = (type, settings) => {
    setIsModalOpen(false);
    if (type === "all") {
      const regenerate = true;
      handleUpdateAllAudios(voicePreview, settings, regenerate);
    } else {
      handleRefresh(type, settings);
    }
  };

  useEffect(() => {
    if (lesson && currentStatus === "LESSON_SCRIPT_SUBMITTED") {
      generateUseCaseAudio(
        lessonId,
        selectedAudioProfile,
        voicePreview,
        "",
        !voicePreview
      )
        .then((res) => {
          setcurrentStatus("AUDIO_GENERATION_INPROGRESS");
        })
        .catch((err) => {});
    }
  }, [lesson, currentStatus, selectedAudioProfile]);

  const handleMenuClick = (model) => {
    if (model === "heygen") {
      setSelectedAvatar((prev) => ({
        ...prev,
        type: "heygen",
      }));
      setContentStep((prev) => prev + 1);
    } else if (model === "sync" || model === "akool") {
      getMyAvatars()
        .then((res) => {
          const defaultavatar = res?.data?.find((item) => item.isDefault);
          if (defaultavatar) {
            setSelectedAvatar({
              avatarId: defaultavatar?._id,
              type: model,
            });
            setContentStep((prev) => prev + 1);
          } else {
            NotificationInstance.warning({
              message:
                "Please select a default leaproad avatar to generate video",
            });
          }
        })
        .catch((err) => {});
    }
  };

  const handleAudioProfileChange = (newProfile) => {
    setSelectedAudioProfile(newProfile);
  };

  const handleAvatarProfileChange = (newAvatar) => {
    setSelectedAvatar(newAvatar);
  };

  const handleConfirm = () => {
    setUpdatePreview(true);
    handleUpdateAllAudios(false, voiceSettings);
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden" && currentAudio !== null) {
        const currentPlayer = audioRefs.current[currentAudio];
        if (currentPlayer && currentPlayer.audioEl.current) {
          currentPlayer.audioEl.current.pause();
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [currentAudio]);

  const handleBack = () => {
    if (lesson?.audioData?.length > 0) {
      const parts = JSON.parse(JSON.stringify(lesson?.audioData));
      const updatedParts = parts.map((part) => ({
        ...part,
        type: part?.segmentType,
      }));
      setChunks(updatedParts);
    }
    setContentStep((prev) => prev - 1);
  };

  return (
    <Row className="course-detail" gutter={[10, 10]}>
      <Col span={24} className="lesson-detail-container">
        <Row>
          <Col span={24}>
            <Flex className="course-detail-header" justify="space-between">
              <span className="course-header-title">Lesson Audio</span>
              {!videoStages.includes(currentStatus) &&
                currentStatus === courseStageTypes.AUDIO_GENERATION_COMPLETED &&
                !updatePreview && (
                  <Flex gap={10} align="center">
                    <Button
                      onClick={() => {
                        setIsModalOpen(true);
                        setupdateItem({
                          item: "all",
                          settings: voiceSetting?.settings,
                        });
                      }}
                    >
                      Voice Settings
                    </Button>
                  </Flex>
                )}
            </Flex>
          </Col>
          <VoiceSettings
            isModalOpen={isModalOpen}
            handleCancel={() => setIsModalOpen()}
            updateItem={updateItem}
            handleOk={updateAudios}
            setVoiceSettings={setVoiceSettings}
          />
          <AudioProfileModal
            visible={audioProfileModal}
            onClose={() => setAudioProfileModal(false)}
            onSelect={handleAudioProfileChange}
            audioProfiles={audioProfiles}
            updateAudio={updateAudio}
            status={currentStatus}
            selectedAudio={selectedAudioProfile}
          />
          <AvatarProfileModal
            visible={avatarModalVisible}
            onClose={() => setAvatarModalVisible(false)}
            onSelect={handleAvatarProfileChange}
          />
        </Row>
        <Row vertical className="course-content" style={{ flex: 1 }}>
          <Col span={24}>
            <Flex vertical gap={20}>
              {voicePreview
                ? chunks.slice(0, 3).map((sentence, index) => (
                    <React.Fragment key={index}>
                      <Flex
                        vertical
                        gap={20}
                        className="lesson-chunk-container"
                      >
                        <TextArea
                          spellCheck={false}
                          readOnly={videoStages.includes(currentStatus)}
                          autoSize={{ minRows: 5, maxRows: 6 }}
                          value={sentence.text}
                          onChange={(e) => handleSentenceChange(index, e)}
                        />

                        {sentence?.url ? (
                          <>
                            <Flex gap={10} align="center">
                              <ReactAudioPlayer
                                src={sentence?.url} // Ensure you're accessing the correct property (url)
                                ref={(element) =>
                                  (audioRefs.current[index] = element)
                                }
                                onPlay={() => handlePlay(index)}
                                controls
                                controlsList="noplaybackrate nodownload"
                                style={{
                                  // display: currentAudio === index ? "block" : "none",
                                  minHeight: 54,
                                }}
                              />
                              {!videoStages.includes(currentStatus) && (
                                <Flex align="center" gap={10}>
                                  <Button
                                    gap={10}
                                    onClick={() => handleRefresh(index)}
                                    style={{
                                      cursor: "pointer",
                                      display: "flex",
                                    }}
                                  >
                                    <ReloadOutlined
                                      style={{
                                        fontSize: "20px",
                                        cursor: "pointer",
                                      }}
                                    />
                                    <span>Regenerate</span>
                                  </Button>
                                </Flex>
                              )}
                              {!videoStages.includes(currentStatus) && (
                                <Flex gap={10} align="center">
                                  <Button
                                    onClick={() => {
                                      setIsModalOpen(true);
                                      setupdateItem({
                                        item: index,
                                        settings: sentence?.voiceSettings,
                                      });
                                    }}
                                  >
                                    Voice Settings
                                  </Button>
                                </Flex>
                              )}
                            </Flex>
                          </>
                        ) : (
                          <Flex gap={20}>
                            <LoadingOutlined
                              disabled
                              style={{
                                fontSize: "20px",
                                cursor: "not-allowed",
                                marginLeft: "10px",
                              }}
                            />
                            <span className="generating">
                              Generating Audio...
                            </span>
                          </Flex>
                        )}
                      </Flex>
                    </React.Fragment>
                  ))
                : chunks.map((sentence, index) => (
                    <React.Fragment key={index}>
                      <Flex
                        vertical
                        gap={20}
                        className="lesson-chunk-container"
                      >
                        <TextArea
                          spellCheck={false}
                          readOnly={videoStages.includes(currentStatus)}
                          autoSize={{ minRows: 5, maxRows: 6 }}
                          value={sentence.text}
                          onChange={(e) => handleSentenceChange(index, e)}
                        />
                        {!sentence.url ? (
                          <Flex gap={20}>
                            <LoadingOutlined
                              disabled
                              style={{
                                fontSize: "20px",
                                cursor: "not-allowed",
                                marginLeft: "10px",
                              }}
                            />
                            <span className="generating">
                              Generating Audio...
                            </span>
                          </Flex>
                        ) : (
                          <Flex align="center" gap={10}>
                            <ReactAudioPlayer
                              src={sentence?.url}
                              ref={(element) =>
                                (audioRefs.current[index] = element)
                              }
                              controls
                              controlsList="noplaybackrate nodownload"
                              style={{
                                minHeight: 54,
                              }}
                              onPlay={() => handlePlay(index)}
                            />
                            <Button
                              gap={10}
                              onClick={() => handleRefresh(index)}
                              style={{
                                cursor: "pointer",
                                display: "flex",
                              }}
                            >
                              <ReloadOutlined
                                style={{
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                              />
                              <span>Regenerate</span>
                            </Button>
                            <Button
                              onClick={() => {
                                setIsModalOpen(true);
                                setupdateItem({
                                  item: index,
                                  settings: sentence?.voiceSettings,
                                });
                              }}
                            >
                              Voice Settings
                            </Button>
                          </Flex>
                        )}
                      </Flex>
                    </React.Fragment>
                  ))}
            </Flex>{" "}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Flex
              flex={1}
              className="course-detail-footer"
              justify="space-between"
            >
              {scriptUpdateStages.includes(currentStatus) &&
                currentStatus !== "AUDIO_GENERATION_INPROGRESS" &&
                !updatePreview && (
                  <Button onClick={() => handleBack()}>Back</Button>
                )}
              {currentStatus === "AUDIO_GENERATION_COMPLETED" && (
                <Flex flex={1} justify="flex-end">
                  {!voicePreview ? (
                    <Flex gap={10}>
                      <Button onClick={() => setAudioProfileModal(true)}>
                        Change Voice Profile
                      </Button>
                      {model === "heygen" && (
                        <Button onClick={() => setAvatarModalVisible(true)}>
                          Select Avatar for Video
                        </Button>
                      )}
                      <Button
                        type="primary"
                        onClick={() => handleMenuClick(model)}
                      >
                        Generate Video
                      </Button>
                    </Flex>
                  ) : (
                    <Flex gap={10}>
                      {!updatePreview && (
                        <Button onClick={() => setAudioProfileModal(true)}>
                          Change Voice Profile
                        </Button>
                      )}
                      <Popconfirm
                        overlayStyle={{ width: 400 }}
                        title="Generate full audio"
                        description="Are you ready to generate the rest of the audio"
                        onConfirm={handleConfirm}
                      >
                        <Button type="primary" loading={updatePreview}>
                          Generate full audio
                          <Tooltip title="Configure the voice settings to your preference and then generate the full audio">
                            <InfoCircleOutlined />
                          </Tooltip>
                        </Button>
                      </Popconfirm>
                    </Flex>
                  )}
                </Flex>
              )}
              {videoStages.includes(currentStatus) && (
                <Button
                  type="primary"
                  onClick={() => setContentStep((prev) => prev + 1)}
                >
                  Next
                </Button>
              )}
            </Flex>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LessonAudio;
