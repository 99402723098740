import React from "react";
import ChatTemplate from "../../components/chatTemplate/chatTemplate";
import styles from "./chatTemplate.module.css";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom";

const ChatTemplatePage = ({ usecaseData, next, setUsecaseData }) => {
  const params = useParams();
  const { useCaseId } = params;

  return (
    <Row align={"center"} className={styles["usecase-chat-template"]}>
      <Col span={20} className={styles["usecase-chat-container"]}>
        <ChatTemplate
          useCaseData={usecaseData}
          useCaseId={useCaseId}
          next={next}
          setUsecaseData={setUsecaseData}
        />
      </Col>
    </Row>
  );
};

export default ChatTemplatePage;
