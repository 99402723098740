import {
  Button,
  Col,
  Empty,
  Flex,
  Input,
  Popconfirm,
  Progress,
  Row,
  Skeleton,
  Tooltip,
} from "antd";
import "./projectDetails.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { timeCreatedOn } from "../../utils/format";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import {
  getProjectById,
  getProjectCourses,
  uploadProjectDocument,
} from "../../api/projectServices";
import UploadProjectDocument from "../../components/modals/uploadProjectDocument";
import InfiniteScroll from "react-infinite-scroll-component";
import CreateCourseModal from "../../components/modals/createCourseModal";
import CourseDocument from "../../components/modals/courseDocument";
import {
  DeleteOutlined,
  SendOutlined,
  AudioOutlined,
  BorderOutlined,
} from "@ant-design/icons";
import NotificationInstance from "../../services/notificationServices";
import UploadTextDocument from "../../components/modals/uploadTextDocument";
import { endAt, limitToLast, onChildAdded, ref } from "firebase/database";
import { dataBase } from "../../components/firebase/firebase";
import { query } from "firebase/firestore";
import { LiveAudioVisualizer } from "react-audio-visualize";
import { documentStages } from "../../utils/constant";
import ChatComponent from "../../components/chatbot/chat";
import CreateVideoModal from "../../components/modals/createVideoModal";

const ProjectDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { projectId } = params;
  const [courses, setCourses] = useState([]);
  const [projectData, setProjectData] = useState();
  const [projectLoading, setProjectLoading] = useState(false);
  const [courseLoading, setCourseLoading] = useState(false);
  const [pageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0); // Adjusted to track total courses
  const [, , setDisplayName] = useOutletContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCourseModalOpen, setIsCourseModalOpen] = useState(false);
  const [isContentModalOpen, setIsContentModalOpen] = useState(false);
  const [isTextModalOpen, setIsTextModalOpen] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState();
  const [audioBlob, setAudioBlob] = useState(null);
  const [currentType, setcurrentType] = useState();
  const [documentText, setDocumentText] = useState("");
  const [audioUploading, setAudioUploading] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const audioStreamRef = useRef(null);
  const [, , , , userDetails] = useOutletContext();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const handleNavigation = (id) => {
    navigate(`/courses/${id}`);
  };

  const handleOk = (id) => {
    navigate(`/courses/${id}`);
    handleCancel();
  };
  const handleCancel = () => {
    setIsCourseModalOpen(false);
    setDocumentText();
  };
  const updateFiles = (data) => {
    setProjectData((prev) => {
      return {
        ...prev,
        projectFiles: [...data, ...prev.projectFiles],
      };
    });
    handleCancel();
  };

  const handleVideoOk = (id) => {
    navigate(`/videos/chat/${id}`);
    handleCancel();
  };
  const handleVideoCancel = () => {
    setIsVideoModalOpen(false);
  };

  const updateProjectFileStatus = ({ fileId, status }) => {
    setProjectData((prev) => {
      return {
        ...prev,
        projectFiles: prev?.projectFiles?.map((file) =>
          file.id === fileId || file._id === fileId
            ? { ...file, status: status }
            : file
        ),
      };
    });
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);
      setcurrentType("audio");
      audioStreamRef.current = stream;

      const chunks = []; // To store the chunks locally within the startRecording function
      recorder.start(100); // Record in chunks of 100ms
      let startTime = Date.now(); // Capture start time

      recorder.ondataavailable = (event) => {
        chunks.push(event.data); // Push data to local chunks array
      };

      recorder.onstop = () => {
        const duration = (Date.now() - startTime) / 1000; // Calculate duration in seconds

        if (duration < 10) {
          // Discard the recording and show notification if less than 10 seconds
          discardRecording();
          NotificationInstance.error({
            message: "Recording Too Short",
            description: "The recording must be at least 10 seconds long.",
          });
        } else {
          const newBlob = new Blob(chunks, { type: "audio/wav" });
          setAudioBlob(newBlob); // Store the final audio blob
        }

        setAudioChunks([]); // Clear any leftover chunks
      };

      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing microphone: ", error);
    }
  };

  // Stop recording
  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
      if (audioStreamRef.current) {
        audioStreamRef.current.getTracks().forEach((track) => track.stop());
      }
    }
  };

  // Discard recording
  const discardRecording = () => {
    setAudioBlob(null);
    setAudioChunks([]);
    setIsRecording(false);
    setcurrentType();
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
  };

  const onSubmit = () => {
    if (currentType === "audio" && audioBlob) {
      setAudioUploading(true);
      const formData = new FormData();
      const blob = new Blob([audioBlob], { type: "audio/wav" });
      formData.append("projectFile", blob, "audio.wav");

      uploadProjectDocument(projectId, formData)
        .then((res) => {
          updateFiles(res?.data);
          NotificationInstance.success({
            message: "Upload Successful",
            description: "The audio record were uploaded successfully.",
          });
          setAudioBlob();
          setcurrentType();
          setAudioUploading(false);
        })
        .catch((err) => {
          NotificationInstance.error({
            message: "Upload Failed",
            description: "Failed to upload the audio. Please try again.",
          });
          console.error(err);
          setAudioUploading(false);
        })
        .finally(() => {
          // setUploading(false);
        });
    } else {
      if (documentText?.trim() !== "") {
        setIsTextModalOpen(true);
      } else {
        setcurrentType();
        NotificationInstance.warning({
          message: "Please enter the document content or record audio",
        });
      }
    }
  };

  const renderCourseCreationButton = () => {
    const allFilesCompleted = projectData?.projectFiles?.every(
      (file) =>
        file.status === documentStages.CONTEXT_BUILD_COMPLETED ||
        file.status === documentStages.CONTEXT_BUILD_FAILED
    );

    if (!allFilesCompleted) {
      return (
        <Flex gap={10} justify="flex-end" flex={1}>
          <Popconfirm
            overlayStyle={{ width: 400 }}
            title="Create Course"
            description="Some files are still processing knowledge extraction. Proceeding will not include their extracted knowledge. Do you want to continue without this knowledge?"
            onConfirm={() => setIsVideoModalOpen(true)}
          >
            <Button type="primary">Create New Video</Button>
          </Popconfirm>
          <Popconfirm
            overlayStyle={{ width: 400 }}
            title="Create Course"
            description="Some files are still processing knowledge extraction. Proceeding will not include their extracted knowledge. Do you want to continue without this knowledge?"
            onConfirm={() => setIsCourseModalOpen(true)}
          >
            <Button type="primary">Create New Course</Button>
          </Popconfirm>
        </Flex>
      );
    } else {
      return (
        <Flex gap={10} justify="flex-end" flex={1}>
          <Button type="primary" onClick={() => setIsVideoModalOpen(true)}>
            Create New Video
          </Button>
          <Button type="primary" onClick={() => setIsCourseModalOpen(true)}>
            Create New Course
          </Button>
        </Flex>
      );
    }
  };

  const getProgressPercentage = (status) => {
    if (status && status === documentStages.CREATED) {
      return "25";
    }
    if (
      status &&
      (status === documentStages.TEXT_EXTRACTION_COMPLETED ||
        status === documentStages.CONTEXT_BUILD_FAILED)
    ) {
      return "50";
    }
    if (status && status === documentStages.CONTEXT_BUILD_COMPLETED) {
      return "100";
    }

    return "0";
  };

  const fetchCourseList = useCallback(async () => {
    if (courseLoading) return;
    setCourseLoading(true);
    try {
      const res = await getProjectCourses(currentPage, pageSize, projectId);
      setCourses((prevCourses) => [...prevCourses, ...res?.data]);
      setTotal(res?.info?.totalCount);
      setCourseLoading(false);
    } catch (err) {
      console.log(err);
      setCourseLoading(false);
    }
  }, [currentPage, pageSize]);

  useEffect(() => {
    fetchCourseList();
  }, [fetchCourseList]);

  useEffect(() => {
    if (projectId) {
      setProjectLoading(true);
      getProjectById(projectId)
        .then((res) => {
          setProjectData(res?.data);
          setDisplayName(["Projects", res?.data?.title]);
          setProjectLoading(false);
        })
        .catch((err) => {
          setProjectLoading(false);
          navigate("/projects");
        });
    }
  }, [projectId]);

  useEffect(() => {
    let unsubscribe;
    const channelRef = query(
      ref(dataBase, `files/${projectId}`),
      endAt,
      limitToLast(1)
    );
    unsubscribe = onChildAdded(channelRef, (snapshot) => {
      const message = snapshot.val();
      updateProjectFileStatus(message);
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return (
    <Row className="projects">
      <Col span={16} className="project-content" style={{ height: "100%" }}>
        <Flex flex={1} vertical className="project-content-wrapper">
          <Flex justify="space-between" align="center">
            <h1>{projectData?.title}</h1>
            <Button type="primary" onClick={() => setIsModalOpen(true)}>
              Add File to Project
            </Button>
            <UploadProjectDocument
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              handleCancel={() => setIsModalOpen(false)}
              handleOk={updateFiles}
              projectId={projectId}
            />
          </Flex>
          <Flex
            flex={1}
            justify="center"
            align="center"
            vertical
            gap={20}
            className="project-content-list"
          >
            {projectData?.projectFiles?.length > 0 ? (
              <Flex
                flex={1}
                vertical
                gap={0}
                className="project-list-wrapper"
                align="flex-start"
                justify="flex-start"
              >
                <CourseDocument
                  projectId={projectId}
                  fileId={selectedFileId}
                  isModalOpen={isContentModalOpen}
                  handleCancel={() => setIsContentModalOpen(false)}
                  setFileIId={setSelectedFileId}
                />
                {projectData?.projectFiles?.map((item) => (
                  <Flex gap={10} className="project-list">
                    <Flex
                      style={{ width: "100%", height: "fit-content" }}
                      gap={20}
                    >
                      <span className="list-type">{item?.type}</span>
                      <Flex
                        vertical
                        className="list-detail"
                        align="flex-start"
                        justify="center"
                      >
                        <span className="list-item">
                          {item?.name || item?.title}
                        </span>
                        <span>
                          Uploaded on {timeCreatedOn(item?.createdAt)}
                        </span>
                      </Flex>
                    </Flex>
                    <Flex gap={20} align="center">
                      <Tooltip
                        title={
                          item.status === documentStages.CREATED || !item.status
                            ? "Text extraction in progress"
                            : item.status ===
                              documentStages.TEXT_EXTRACTION_COMPLETED
                            ? "Knowledge extraction in progress"
                            : item.status ===
                              documentStages.CONTEXT_BUILD_FAILED
                            ? "Knowledge extraction failed - Word limit exceeded."
                            : ""
                        }
                      >
                        <Progress
                          size={35}
                          type="circle"
                          showInfo={
                            item?.status ===
                              documentStages.CONTEXT_BUILD_COMPLETED ||
                            item?.status === documentStages.CONTEXT_BUILD_FAILED
                          }
                          percent={getProgressPercentage(item?.status)}
                          status={
                            item?.status === documentStages.CONTEXT_BUILD_FAILED
                              ? "exception"
                              : ""
                          }
                        />
                      </Tooltip>

                      {item.status !== documentStages.CONTEXT_BUILD_FAILED && (
                        <Button
                          loading={!item?.status || item?.status === "CREATED"}
                          className="list-action"
                          onClick={() => {
                            setSelectedFileId(item?.id);
                            setIsContentModalOpen(true);
                          }}
                        >
                          {!item?.status || item?.status === "CREATED"
                            ? "Processing"
                            : "View Content"}
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            ) : (
              <Empty
                description={
                  projectLoading ? (
                    "Loading Documents..."
                  ) : (
                    <div>
                      <p>No knowledge has been uploaded yet.</p>
                      <p>
                        You can <strong>upload a document</strong>,{" "}
                        <strong>type your knowledge</strong>, or{" "}
                        <strong>send it as audio</strong>.
                      </p>
                    </div>
                  )
                }
              />
            )}
          </Flex>
          <Flex className="project-input-container" gap={20} align="center">
            <UploadTextDocument
              text={documentText}
              isModalOpen={isTextModalOpen}
              setIsModalOpen={setIsTextModalOpen}
              handleCancel={() => setIsTextModalOpen(false)}
              handleOk={updateFiles}
              projectId={projectId}
            />
            {!audioBlob && currentType !== "audio" && (
              <Input.TextArea
                placeholder="Enter the knowledge you want to share..."
                className="project-input"
                autoSize={{ minRows: 1, maxRows: 10 }}
                onChange={(e) => setDocumentText(e.target.value)}
                value={documentText}
              />
            )}
            {!audioBlob && (
              <Flex flex={1} align="center">
                <Flex flex={1}>
                  {mediaRecorder && isRecording && (
                    <LiveAudioVisualizer
                      mediaRecorder={mediaRecorder}
                      width={500}
                      height={50}
                      barPlayedColor="#000" // The played portion of the visualizer
                      barColor="#000" // Customize the bar color here
                    />
                  )}
                </Flex>
                {isRecording ? (
                  <Button
                    className="send-document"
                    onClick={() => stopRecording()}
                  >
                    <BorderOutlined />
                  </Button>
                ) : (
                  <Button
                    className="send-document"
                    onClick={() => startRecording()}
                  >
                    <AudioOutlined />
                  </Button>
                )}
              </Flex>
            )}
            {audioBlob && (
              <>
                <Flex flex={1}>
                  <audio controls src={URL.createObjectURL(audioBlob)} />
                </Flex>
                <span className="send-document" onClick={discardRecording}>
                  <DeleteOutlined />
                </span>
              </>
            )}
            {!isRecording && (
              <Button
                loading={audioUploading}
                className="send-document"
                onClick={() => onSubmit()}
              >
                {!audioUploading && <SendOutlined />}
              </Button>
            )}
          </Flex>
        </Flex>
      </Col>
      <Col span={8} className="project-course">
        <Flex vertical flex={1} className="project-course-container">
          <Flex
            justify="space-between"
            align="center"
            className="project-course-header"
            gap={20}
            wrap="wrap"
          >
            <h2>Courses</h2>
            {renderCourseCreationButton()}
            <CreateCourseModal
              isModalOpen={isCourseModalOpen}
              handleCancel={handleCancel}
              handleOk={handleOk}
              itemType={"Course"}
              project={projectId}
            />
            <CreateVideoModal
              isModalOpen={isVideoModalOpen}
              handleCancel={handleVideoCancel}
              handleOk={handleVideoOk}
              videoType="TEMPLATE"
              project={projectId}
            />
          </Flex>
          <Flex flex={1} className="project-course-list" id="scrollableDiv">
            {courses?.length > 0 ? (
              <InfiniteScroll
                style={{ width: "100%" }}
                dataLength={courses?.length || 0}
                next={() => setCurrentPage((prev) => prev + 1)}
                hasMore={courses.length < total}
                loader={
                  <div style={{ width: "100%", padding: 10 }}>
                    <Skeleton.Button
                      className="skeleton-loader"
                      block
                      active
                      size={100}
                      style={{ width: "100%", padding: 10 }}
                    />
                  </div>
                }
                scrollableTarget="scrollableDiv"
              >
                <Flex vertical gap={10} className="course-list-container">
                  {courses?.map((item) => (
                    <Flex
                      key={item?._id}
                      flex={1}
                      vertical
                      align="flex-start"
                      gap={2}
                      className="course-list"
                      onClick={() => handleNavigation(item?._id)}
                    >
                      <span className="project-course-title">
                        {item?.title}
                      </span>
                      <span>Created on {timeCreatedOn(item?.createdAt)}</span>
                    </Flex>
                  ))}
                </Flex>
              </InfiniteScroll>
            ) : (
              <Flex flex={1} align="center" justify="center">
                <Empty
                  description={
                    courseLoading ? (
                      "Loading Courses..."
                    ) : (
                      <div>
                        <p>No courses related to this project are available.</p>
                        <p>
                          You can create a new course by clicking the button{" "}
                          <strong>"Create New Course"</strong>.
                        </p>
                      </div>
                    )
                  }
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Col>
      <ChatComponent userDetails={userDetails} />
    </Row>
  );
};

export default ProjectDetails;
