import {
  Button,
  Flex,
  Input,
  Modal,
  Popconfirm,
  Progress,
  Segmented,
  Select,
  Tabs,
  Upload,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./modal.css";
import { ReactComponent as UploadIcon } from "../../assets/images/upload.svg";
import { ReactComponent as Image } from "../../assets/images/placeholder.svg";
import NotificationInstance from "../../services/notificationServices";
import { CloseOutlined } from "@ant-design/icons";
import { Upload as TusUpload } from "tus-js-client";

import {
  UploadFile,
  cancelToken,
  createFileRecord,
  getAllVideos,
  getFileUrl,
  updateProgress,
} from "../../api/adminService";
import { getVideoList, getVideoUrl } from "../../api/hubSpotService";

const UploadVideoModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  type,
  journeyType,
  VideoIndex,
  section,
  milestoneIndex,
  videoDetail,
  handleDelete,
  handleTaskUpdation,
}) => {
  const [file, setFile] = useState({
    file: null,
    updated: false,
  });
  const [previewImage, setPreviewImage] = useState();
  const [progress, setProgress] = useState("");
  const [uploading, setUploading] = useState(false);
  const [title, setTitle] = useState("");
  const [extension, setExtension] = useState();
  const [videoData, setvideoData] = useState();
  const [loading, setloading] = useState(false);
  const lastApiCallTimeRef = useRef(Date.now());
  const [fileId, setfileId] = useState();
  const [selectedOption, setselectedOption] = useState("Upload");
  const [videos, setVideos] = useState({ journey: [], training: [] });
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [VideoType, setType] = useState("Journey");
  const [total, setTotal] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");

  const uploadRefs = useRef({});

  const handleChange = async (info) => {
    if (!info.file.type.match(/video\/(mp4|avi|mkv|mov|x-matroska)/)) {
      return;
    }
    const extension = info?.file?.name?.slice(
      info?.file?.name?.lastIndexOf(".") + 1
    );
    setExtension(`.${extension}`);
    setFile({ file: info.file, updated: true });
    setPreviewImage(Image);
  };

  const customRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const beforeUpload = (file) => {
    const isvideo = file.type.match(/video\/(mp4|avi|mkv|mov|x-matroska)/);
    if (!isvideo) {
      NotificationInstance.warning({
        message: "Please upload only MP4, AVI, MKV or MOV video files!",
      });
      return false;
    }

    return true;
  };
  const onSubmit = () => {
    if (file.file) {
      if (file.updated) {
        if (!title) {
          NotificationInstance.warning({
            message: "Please enter a title",
          });
          return;
        }
        const data = {
          fileType: type,
          title: title,
          originalFileName: file.file.name,
          mimeType: file.file.type,
          journeyType: journeyType,
        };
        setloading(true);
        createFileRecord(data)
          .then((res) => {
            if (res.data._id) {
              const fileData = new FormData();
              fileData.append("file", file.file.originFileObj);
              setfileId(res?.data?._id);
              UploadFile(res?.data?._id)
                .then((response) => {
                  setUploading(true);
                  handleUpload(response.data, res.data._id);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => console.log(err));
      } else {
        handleCancel();
      }
    } else {
      NotificationInstance.warning({
        message: "Please upload only MP4, AVI, MKV or MOV video files!",
      });
    }
  };

  const handleUpload = (data, id) => {
    const upload = new TusUpload(file.file.originFileObj, {
      endpoint: "https://video.bunnycdn.com/tusupload",
      retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
      headers: {
        AuthorizationSignature: data.signature, // SHA256 signature (library_id + api_key + expiration_time + video_id)
        AuthorizationExpire: data.expiration, // Expiration time as in the signature,
        VideoId: data.videoGuId, // The guid of a previously created video object through the Create Video API call
        LibraryId: data.libraryId,
      },
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      // Callback for errors which cannot be fixed using retries
      onError: (error) => {
        console.log("Failed because: " + error);
        updateNewProgress("FAILED", progress, id);

        setloading(false);
      },
      // Callback for reporting upload progress
      onProgress: (bytesUploaded, bytesTotal) => {
        const percentage = Math.floor((bytesUploaded / bytesTotal) * 100);
        setProgress(percentage < 100 ? percentage : 99);
        const now = Date.now();
        if (now - lastApiCallTimeRef.current >= 5000) {
          updateNewProgress("PENDING", percentage, id);
          lastApiCallTimeRef.current = now; // Update the ref directly
        }
      },
      onSuccess: () => {
        updateNewProgress("SUCCESS", 100, id);
      },
    });
    uploadRefs.current[id] = upload;

    upload.findPreviousUploads().then((previousUploads) => {
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }
      upload.start();
    });
  };
  const updateNewProgress = (state, progress, id) => {
    updateProgress(id, {
      status: state,
      progress: progress,
    })
      .then((res) => {
        if (state === "SUCCESS") {
          reset();
          if (videoDetail.task !== false) {
            handleTaskUpdation(
              selectedOption,
              id,
              section,
              milestoneIndex,
              videoDetail.task
            );
          } else {
            handleOk(selectedOption, id, section, milestoneIndex, VideoIndex);
          }
          handleCancel();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const formatSize = (size) => {
    if (size < 1024) {
      return `${size}B`;
    } else if (size < 1024 * 1024) {
      return `${Math.floor(size / 1024)}Kb`;
    } else {
      return `${Math.floor(size / (1024 * 1024))}Mb`;
    }
  };
  const onModalClose = () => {
    if (!loading) {
      reset();
      handleCancel();
    }
  };
  const cancelUpload = (id) => {
    const upload = uploadRefs.current[id];
    if (upload) {
      upload.abort();
    }
  };
  const onPopupClose = () => {
    cancelUpload(fileId);
    updateNewProgress("FAILED", progress, fileId);
    cancelToken();
    reset();
    handleCancel();
  };
  const onchange = (val) => {
    setTitle(val.target.value);
  };

  const reset = () => {
    setFile({ image: null, updated: false });
    setPreviewImage();
    setloading(false);
    setUploading(false);
    setTitle("");
    setProgress("");
    setSelectedVideo();
    setselectedOption("Upload");
  };

  useEffect(() => {
    if (videoDetail.index) {
      setloading(true);
      getVideoUrl("", [videoDetail.index])
        .then((res) => {
          if (res.data) {
            setvideoData(res.data);
            setloading(false);
            setTitle(res.data?.title);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setTitle("");
    }
  }, [videoDetail?.index]);

  useEffect(() => {
    getVideoList("Journey")
      .then((res) => {
        const videoOptions = res.data.map((video) => ({
          label: video.title,
          value: video._id,
        }));
        setVideos((prev) => ({
          ...prev,
          journey: videoOptions,
        }));
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    getAllVideos(
      searchKey,
      ["VIDEO_GENERATION_COMPLETED"],
      "",
      "",
      20,
      currentPage
    )
      .then((res) => {
        const videoOptions = res.data.map((video) => ({
          label: video?.title,
          value: video?.videoId,
        }));
        setVideos((prev) => ({
          ...prev,
          training:
            currentPage === 1
              ? [...videoOptions]
              : [...videos?.training, ...videoOptions],
        }));
        setTotal(res?.info?.totalCount);
      })
      .catch((err) => console.log(err));
  }, [currentPage, searchKey]);

  const handleVideoSelect = (value, type) => {
    setSelectedVideo((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleAddVideos = () => {
    const allSelectedVideos = [
      ...(selectedVideo.journey || []),
      ...(selectedVideo.training || []),
    ];
    if (allSelectedVideos?.length > 0) {
      if (videoDetail.task !== false) {
        handleTaskUpdation(
          selectedOption,
          allSelectedVideos,
          section,
          milestoneIndex,
          videoDetail.task
        );
      } else {
        handleOk(
          selectedOption,
          allSelectedVideos,
          section,
          milestoneIndex,
          VideoIndex
        );
      }
      handleCancel();
      reset();
    } else {
      NotificationInstance.warning({
        message: "Please select a video",
      });
    }
  };
  const onScroll = (e) => {
    const { target } = e;

    const isAtBottom =
      Math.ceil(target.scrollTop + target.clientHeight) >= target.scrollHeight;
    if (isAtBottom && videos?.training?.length < total) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  let debounceTimeout;
  const debouncedSearch = (input) => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      setCurrentPage(1);
      setSearchKey(input);
    }, 500);
  };
  return (
    <Modal
      centered
      maskClosable={false}
      width={450}
      title={selectedOption === "Add" ? "Add video" : "Upload video"}
      open={isModalOpen}
      onCancel={onModalClose}
      closeIcon={
        loading ? (
          <Popconfirm
            description={
              <span>
                Upload is in progress.
                <br /> Are you sure you want to leave?
              </span>
            }
            okText="Yes"
            cancelText="No"
            onConfirm={onPopupClose}
          >
            <CloseOutlined />
          </Popconfirm>
        ) : (
          <CloseOutlined />
        )
      }
      footer={() =>
        videoDetail?.index ? (
          <div className="action-buttons delete">
            <Button
              className="discard-button"
              onClick={() =>
                handleDelete(
                  videoDetail?.index,
                  section,
                  milestoneIndex,
                  VideoIndex,
                  videoDetail?.task !== false ? true : false,
                  videoDetail?.task
                )
              }
            >
              Delete
            </Button>
          </div>
        ) : (
          <div className="action-buttons">
            {selectedOption === "Upload" ? (
              <Button disabled={loading} type="primary" onClick={onSubmit}>
                Upload
              </Button>
            ) : (
              <Button
                disabled={loading}
                type="primary"
                onClick={handleAddVideos}
              >
                Add
              </Button>
            )}
            <Button
              disabled={loading}
              className="cancel-button"
              onClick={onModalClose}
            >
              Cancel
            </Button>
          </div>
        )
      }
    >
      {!videoDetail?.index && (
        <Segmented
          className="video-options"
          disabled={uploading || loading}
          options={[
            {
              label: "Upload",
              value: "Upload",
            },
            {
              label: "Add",
              value: "Add",
            },
          ]}
          onChange={(val) => setselectedOption(val)}
        />
      )}
      {selectedOption === "Upload" ? (
        uploading ? (
          <div className="thumbnail-upload">
            <Image />
            {file?.file?.name && (
              <span className="image-name">{file?.file?.name}</span>
            )}
            <Progress
              showInfo={false}
              strokeColor={"#262528"}
              percent={progress}
            />
          </div>
        ) : loading ? (
          <div className="thumbnail-upload">
            <div className="video-loader">
              <div className="loader"></div>
            </div>
          </div>
        ) : (
          <div className="thumbnail-upload">
            {previewImage ? (
              <>
                <Image />
                {file?.file?.name && (
                  <span className="image-name">{file?.file?.name}</span>
                )}
                {file?.image?.size && (
                  <span className="image-size">
                    {formatSize(file?.image?.size)}
                  </span>
                )}
              </>
            ) : videoDetail?.index ? (
              <>
                <iframe src={videoData?.url} />
              </>
            ) : (
              <>
                <UploadIcon />
                <span className="browse">Browse and Upload a video</span>
                <span className="supported-files">
                  MP4, AVI, MKV or MOV formats
                </span>
              </>
            )}
            {!videoDetail.index && (
              <Upload
                listType="picture"
                maxCount={1}
                multiple={false}
                onChange={handleChange}
                customRequest={customRequest}
                beforeUpload={beforeUpload}
                showUploadList={false}
                accept=".mp4,.avi,.mkv,.mov"
              >
                <Button>
                  {file.file || videoDetail.index
                    ? "Change Video"
                    : "Browse File"}
                </Button>
              </Upload>
            )}
            <Input
              readOnly={videoDetail?.index}
              value={title}
              placeholder="Enter a title"
              onChange={onchange}
            />
          </div>
        )
      ) : (
        // <Tabs
        //   defaultActiveKey="1"
        //   items={[
        //     {
        //       key: "Journey",
        //       label: "Journey",
        //       children: (
        //         <Select
        //           placeholder="Select a video"
        //           onChange={handleVideoSelect}
        //           mode="multiple"
        //           style={{ width: "100%" }}
        //           disabled={loading}
        //           options={videos}
        //         />
        //       ),
        //     },
        //     {
        //       key: "Training",
        //       label: "Training",
        //       children: (
        //         <Select
        //           placeholder="Select a video"
        //           onChange={handleVideoSelect}
        //           mode="multiple"
        //           style={{ width: "100%" }}
        //           disabled={loading}
        //           options={videos}
        //         />
        //       ),
        //     },
        //   ]}
        //   onChange={(val) => setType(val)}
        // />
        <Flex vertical gap={20}>
          <Flex vertical>
            <span>Journey Video</span>
            <Select
              placeholder="Select a video"
              onChange={(value) => handleVideoSelect(value, "journey")}
              mode="multiple"
              style={{ width: "100%" }}
              disabled={loading}
              options={videos.journey}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Flex>
          <Flex vertical>
            <span>Training Video</span>
            <Select
              placeholder="Select a video"
              onChange={(value) => handleVideoSelect(value, "training")}
              mode="multiple"
              style={{ width: "100%" }}
              disabled={loading}
              options={videos.training}
              onPopupScroll={onScroll}
              filterOption={false}
              onSearch={(val) => {
                debouncedSearch(val);
              }}
            />
          </Flex>
        </Flex>
      )}
    </Modal>
  );
};

export default UploadVideoModal;
