import { Button, Flex, Input, Popconfirm, Select, Tooltip } from "antd";
import { ReactComponent as Edit } from "../../../assets/images/editPen.svg";
import { ReactComponent as Video } from "../../../assets/images/videoPrompt.svg";
import { ReactComponent as Subtitle } from "../../../assets/images/subtitle.svg";
import { ReactComponent as Slide } from "../../../assets/images/slide.svg";
import { capitalizeInput, videoStages } from "../../../utils/format";
import {
  CloseOutlined,
  CheckOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useOutletContext } from "react-router-dom";
import NotificationInstance from "../../../services/notificationServices";

const CustomHeader = ({
  title,
  titleDisabled,
  setTitleDisabled,
  setTitle,
  handleEntityTypeChange,
  selectedTexts,
  buttonsRef,
  updateTitle,
  status,
  usecaseData,
  isEntityAtSelection,
  removeEntitiesInSubsentence,
  model,
  setModel,
  setEditorChanged,
}) => {
  const [, , , , userDetails] = useOutletContext();

  const items = [
    { type: "SUBTITLE", icon: <Subtitle />, title: "Keyword" },
    { type: "VIDEO", icon: <Video />, title: "Stock Video" },
    { type: "SLIDE", icon: <Slide />, title: "Slide" },
  ];

  const handleModelChange = (val) => {
    if (val === "heygen" && !userDetails.heyGenAvatarId) {
      NotificationInstance.info({
        message: "You don't have a heygen avatar set up",
      });
    } else {
      setModel(val);
      setEditorChanged(true);
    }
  };

  return (
    <Flex flex={1} justify="space-between" align="center" gap={50}>
      {titleDisabled ? (
        <span className="usecase-header-title">{title}</span>
      ) : (
        <Input
          value={title}
          className={`usecase-title-input ${!titleDisabled ? "active" : ""}  ${
            title.error ? "is-invalid" : ""
          }`}
          disabled={titleDisabled}
          defaultValue={""}
          id="journey-title"
          onChange={(e) => setTitle(e.target.value)}
        />
      )}
      <Flex gap={20}>
        {titleDisabled ? (
          <Button
            className="edit-title"
            onClick={() => setTitleDisabled(false)}
            icon={<Edit />}
            color="white"
          >
            Edit Title
          </Button>
        ) : (
          <Flex gap={10}>
            <Button
              className="edit-title"
              onClick={() => updateTitle()}
              color="white"
            >
              <CheckOutlined />
            </Button>
            <Button
              className="edit-title"
              onClick={() => {
                setTitleDisabled(true);
                setTitle(usecaseData?.title);
              }}
              color="white"
            >
              <CloseOutlined />
            </Button>
          </Flex>
        )}
        {!videoStages.includes(status) &&
          (isEntityAtSelection ? (
            <Popconfirm
              overlayStyle={{ width: 400 }}
              title="Script"
              description="Removing entities will delete all keywords, slides, and videos added. Do you want to continue?"
              onConfirm={(e) => {
                e.preventDefault();
                e.stopPropagation();
                removeEntitiesInSubsentence(); // Call your function to remove entities
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button className="edit-title">Remove</Button>
            </Popconfirm>
          ) : (
            <>
              <Flex gap={20} ref={buttonsRef}>
                {items.map((item) => (
                  <Tooltip
                    title={`${
                      selectedTexts ? "" : "Please select at least one sentence"
                    }`}
                  >
                    <Button
                      className="edit-title"
                      disabled={!selectedTexts}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleEntityTypeChange(item.type);
                      }}
                      icon={item.icon}
                      color="white"
                    >
                      Add {item.title}
                    </Button>
                  </Tooltip>
                ))}
              </Flex>
              <Select
                value={model}
                style={{ width: 140 }}
                placeholder="Avatar Model"
                onChange={(val) => {
                  handleModelChange(val);
                }}
                options={[
                  { label: "Studio", value: "heygen" },
                  { label: "Akool (Beta)", value: "akool" },
                  { label: "Sync (Beta)", value: "sync" },
                ]}
              />
              <Tooltip title="Choose the desired avatar model">
                <InfoCircleOutlined />
              </Tooltip>
            </>
          ))}
      </Flex>
    </Flex>
  );
};
export default CustomHeader;
