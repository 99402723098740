import React, { useEffect, useState } from "react";
import "./journeyDetail.css";
import { useNavigate, useParams } from "react-router-dom";
import { getFileUrl, getJourneyDetails } from "../../api/adminService";
import { Button, Collapse, Empty, Flex, Skeleton } from "antd";
import { CurrentStatusTag } from "../tags/tags";
import { ReactComponent as Edit } from "../../assets/images/edit.svg";
import { timeSinceLastSaved } from "../../utils/format";
import { ReactComponent as Video } from "../../assets/images/video.svg?react";
import { ReactComponent as ImageIcon } from "../../assets/images/image.svg?react";
import { ReactComponent as Doc } from "../../assets/images/doc.svg?react";
import ContentPlayer from "../modals/contentModal";
const JourneyDetail = ({ setDisplayName }) => {
  const params = useParams();
  const { Panel } = Collapse;
  const [journey, setJourney] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedContent, setselectedContent] = useState();

  const [loadingIds, setLoadingIds] = useState({});
  const [videoUrls, setVideoUrls] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    if (params.journeyId) {
      getJourneyDetails(params.journeyId)
        .then((res) => {
          setLoading(false);
          if (res?.data?.length > 0) {
            setJourney(res?.data[0]);
            setDisplayName([
              res?.data[0]?.categoryInfo?.name,
              res?.data[0]?.title,
            ]);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [params]);
  const onCollapseChange = async (activeKeys) => {
    activeKeys.forEach(async (key) => {
      const [sectionIndex, milestoneIndex] = key.split("-").map(Number);
      const activeMilestone =
        journey?.plan[sectionIndex]?.milestones[milestoneIndex];
      if (activeMilestone) {
        const fileIds = activeMilestone.fileIds || [];
        const videoIds = activeMilestone.videoIds || [];
        const files = [...fileIds, ...videoIds];
        const taskFiles = (activeMilestone.tasks || []).reduce((acc, task) => {
          const taskFileIds = task.fileIds || [];
          const taskVideoIds = task.videoIds || [];
          return acc.concat(taskFileIds, taskVideoIds);
        }, []);
        const allFiles = [...files, ...taskFiles];
        let newFiles;
        if (journey.plan) {
          const existingFileIds = Object.keys(videoUrls);
          newFiles = allFiles.filter((id) => !existingFileIds.includes(id));
        } else {
          newFiles = allFiles;
        }
        const currentLoadingIds = { ...loadingIds };

        newFiles.forEach((id) => {
          currentLoadingIds[id] = true;
        });

        setLoadingIds(currentLoadingIds);
        if (newFiles.length > 0) {
          getFileUrl(newFiles)
            .then((res) => {
              if (res.data.length > 0) {
                const actionResult = res.data;
                const newVideoUrls = { ...videoUrls };
                actionResult.forEach((file) => {
                  newVideoUrls[file._id] = file;
                });
                setVideoUrls(newVideoUrls);

                setLoadingIds({});
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    });
  };
  useEffect(() => {
    if (journey.plan) {
      onCollapseChange(["0-0"]);
    }
  }, [journey]);
  const renderContent = (data) => {
    let Icon;
    if (data.fileType === "VIDEO") {
      Icon = <Video />;
    } else if (data.fileType === "IMAGE") {
      Icon = <ImageIcon />;
    } else {
      Icon = <Doc />;
    }
    return (
      <Flex
        className="content"
        gap={20}
        onClick={() => {
          setIsOpen(true);
          setselectedContent(data);
        }}
      >
        <div className="content-icon">{Icon}</div>
        <Flex vertical gap={8}>
          <span className="item-title">{data?.title}</span>
          <span className="item-type">{data?.fileType}</span>
        </Flex>
      </Flex>
    );
  };
  if (loading) {
    return (
      <div className="journey-detail-loader">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="journey-detail">
      <div className="journey-detail-header">
        <div className="journey-header-container container">
          <div className="journey-details">
            <img
              src={
                journey?.image ??
                `https://ui-avatars.com/api/?name=${journey?.title}&background=EEEBF3&color=000`
              }
              alt="journey image"
              className="journey-image"
            />
            <div className="journey-titles">
              <h3>{journey?.title}</h3>
              <div className="journey-type-status">
                <CurrentStatusTag status={journey?.status} />
                <p className="last-update">
                  Changes saved{" "}
                  {timeSinceLastSaved(new Date(journey?.updatedAt))}
                </p>
              </div>
            </div>
          </div>
          <div className="journey-actions">
            <Button
              onClick={() =>
                navigate(`/${params.categoryId}/edit/${params.journeyId}`)
              }
              icon={<Edit />}
              color="white"
            >
              Edit Journey
            </Button>
          </div>
        </div>
      </div>
      {journey &&
      (!journey.plan || journey.plan.length === 0) &&
      (!journey.questions || journey.questions.length === 0) ? (
        <div className="empty-container">
          <Empty description="No details found" />
        </div>
      ) : (
        <div className="journey-detail-content container">
          {journey?.questions?.length > 0 && (
            <div className="journey-detail-question">
              <h2 className="content-title">Questions</h2>
              <div className="detail-container">
                {journey?.questions.map((item, index) => (
                  <div className="generated-question-container">
                    <div className="question-number">{index + 1}</div>
                    <span>{item}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {isOpen && (
            <ContentPlayer
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              data={selectedContent}
              setselectedContent={setselectedContent}
              setVideoUrls={setVideoUrls}
            />
          )}
          {journey?.plan?.length > 0 &&
            journey?.plan?.map((item, index) => (
              <div
                className={`journey-detail-sections ${
                  journey?.questions?.length > 0 ? "pre-defined" : "fixed"
                }`}
              >
                <span className="section-number">Section {index + 1}</span>
                <h2 className="content-title">{item?.section}</h2>
                <div className="detail-container">
                  {item?.milestones?.length > 0 && (
                    <div className="generated-milestones">
                      <p className="section-item-title">MILESTONES</p>
                      <div className="detail-container">
                        <Collapse
                          expandIconPosition="end"
                          className="detail-container"
                          onChange={onCollapseChange}
                          defaultActiveKey={["0-0"]}
                        >
                          {item?.milestones?.map(
                            (milestone, milestoneIndex) => (
                              <Panel
                                header={
                                  <div className="generated-milestone-container">
                                    <div className="question-number">
                                      {milestoneIndex + 1}
                                    </div>
                                    <span>{milestone?.title}</span>
                                  </div>
                                }
                                key={`${index}-${milestoneIndex}`}
                                className="milestone-header section-content"
                              >
                                <Flex
                                  vertical
                                  gap={30}
                                  className="milestone-details"
                                >
                                  {/* {milestone?.tasks?.length > 0 && (
                                    <div className="task-details">
                                      <span className="section-number">
                                        Task
                                      </span>
                                      <ol className="generated-task-container">
                                        {milestone?.tasks?.map(
                                          (task, index) => (
                                            <li>
                                              {task?.task}{" "}
                                              {task?.videoUrl && (
                                                <a
                                                  href={task?.videoUrl}
                                                  target="_blank"
                                                >
                                                  {" "}
                                                  - {task?.videoUrl}
                                                </a>
                                              )}
                                            </li>
                                          )
                                        )}
                                      </ol>
                                    </div>
                                  )} */}
                                  {milestone.fileIds?.map((fileId) => (
                                    <div key={fileId}>
                                      {loadingIds[fileId] ? (
                                        <Skeleton.Button
                                          size="large"
                                          active
                                          block
                                        />
                                      ) : videoUrls[fileId] ? (
                                        renderContent(videoUrls[fileId])
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  ))}

                                  {milestone?.videoIds?.map((videoId) => (
                                    <div key={videoId}>
                                      {loadingIds[videoId] ? (
                                        <Skeleton.Button active block />
                                      ) : videoUrls[videoId] ? (
                                        renderContent(videoUrls[videoId])
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  ))}
                                  <Flex gap={20}>
                                    {milestone?.videoURLs?.map(
                                      (video, index) => {
                                        // Regex pattern to validate URLs
                                        const isValidUrl =
                                          /^(http|https):\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:?#@!$&'()*+,;=]*)*$/.test(
                                            video
                                          );

                                        // Only render if the URL is valid
                                        return isValidUrl ? (
                                          <Flex
                                            key={index}
                                            className="content"
                                            gap={20}
                                            onClick={() => {
                                              setIsOpen(true);
                                              setselectedContent({
                                                url: video,
                                              });
                                            }}
                                          >
                                            <div className="content-icon">
                                              <Video />
                                            </div>
                                          </Flex>
                                        ) : null; 
                                      }
                                    )}
                                  </Flex>
                                  {milestone?.tasks?.map((task, taskIndex) => (
                                    <Flex
                                      className="task-collapse-container"
                                      vertical
                                      gap={24}
                                      key={taskIndex}
                                    >
                                      <Flex
                                        key={task?.id}
                                        className="task-item"
                                        gap="middle"
                                        justify="space-between"
                                      >
                                        <h4 className="task-title">
                                          {taskIndex + 1}. {task?.task}
                                        </h4>
                                      </Flex>
                                      {(task?.fileIds?.length > 0 ||
                                        task?.videoIds?.length > 0 ||
                                        task?.videoURLs?.length > 0) && (
                                        <Flex
                                          vertical
                                          gap="middle"
                                          className="task-collapse"
                                        >
                                          {task?.fileIds?.map((fileId) => (
                                            <div key={fileId}>
                                              {loadingIds[fileId] ? (
                                                <Skeleton.Button active block />
                                              ) : videoUrls[fileId] ? (
                                                renderContent(videoUrls[fileId])
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          ))}

                                          {task?.videoIds?.map((videoId) => (
                                            <div key={videoId}>
                                              {loadingIds[videoId] ? (
                                                <Skeleton.Button active block />
                                              ) : videoUrls[videoId] ? (
                                                renderContent(
                                                  videoUrls[videoId]
                                                )
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          ))}
                                          <Flex wrap gap="small">
                                            {task?.videoURLs?.map((video) => (
                                              <Flex
                                                className="content"
                                                gap={20}
                                                onClick={() => {
                                                  setIsOpen(true);
                                                  setselectedContent({
                                                    url: video,
                                                  });
                                                }}
                                              >
                                                <div className="content-icon">
                                                  <Video />
                                                </div>
                                              </Flex>
                                            ))}
                                          </Flex>
                                        </Flex>
                                      )}
                                    </Flex>
                                  ))}
                                </Flex>
                              </Panel>
                            )
                          )}
                        </Collapse>
                      </div>
                    </div>
                  )}
                  {item?.additionalPrompts?.length > 0 && (
                    <div className="generated-milestones">
                      <p className="section-item-title">PROMPTS</p>
                      <div className="detail-container">
                        {item?.additionalPrompts?.map((item, index) => (
                          <div className="generated-prompt-container">
                            <div className="question-number">{index + 1}</div>
                            <span>{item}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default JourneyDetail;
