import {
  Button,
  Modal,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Skeleton,
  Tooltip,
  Popconfirm,
  List,
  Radio,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  convertFromRaw,
  convertToRaw,
  CompositeDecorator,
  Editor,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
} from "draft-js";
import "draft-js/dist/Draft.css";
import "./scriptUpdation.css";
import UploadVideoPresentation from "../../modals/uploadVideoPresentation";
import {
  audioStages,
  capitalizeInput,
  scriptStages,
  scriptUpdateStages,
  videoStages,
} from "../../../utils/format";
import {
  createBlocksFromEntities,
  rawContent,
  TokenSpan,
} from "../../../utils/constant";
import {
  getAudioProfiles,
  getStockVideos,
  updateUseCase,
  updateUseCaseForPreview,
  updateUsecaseScript,
  uploadUseCasePPT,
} from "../../../api/adminService";
import NotificationInstance from "../../../services/notificationServices";
import AudioProfileModal from "../../modals/audioProfilesModal";
import CustomHeader from "./scriptHeader";

const ScriptUpdation = ({
  type,
  usecaseData,
  title,
  titleDisabled,
  setTitleDisabled,
  setTitle,
  useCaseId,
  setUsecaseData,
  status,
  setStatus,
  setCurrent,
  setScriptParts,
  selectedAudioProfile,
  setSelectedAudioProfile,
  audioProfiles,
  model,
  setModel,
  setVoicePreview,
}) => {
  const [loading, setLoading] = useState(false);
  const [slidePreview, setSlidePreview] = useState();
  const [generating, setgenerating] = useState(false);
  const [pages, setPages] = useState(false);
  const [videos, setVideos] = useState();
  const [selectedVideo, setSelectedVideo] = useState();
  const [selectedState, setselectedState] = useState();
  const [editorChanged, setEditorChanged] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [subtitleText, setSubtitleText] = useState("");
  const [audioProfileModal, setAudioProfileModal] = useState(false);

  const generateOptions = (num) => {
    return Array.from({ length: num }, (_, i) => ({
      label: i + 1,
      value: i + 1,
    }));
  };

  useEffect(() => {
    if (usecaseData) {
      const decorator = new CompositeDecorator([
        {
          strategy: getEntityStrategy("SEGMENTED"),
          component: TokenSpan,
        },
        {
          strategy: getEntityStrategy("MUTABLE"),
          component: TokenSpan,
        },
        {
          strategy: getEntityStrategy("SEGMENTED"),
          component: TokenSpan,
        },
      ]);
      let blocks;
      // Create blocks from script parts
      if (usecaseData.scriptParts && usecaseData.scriptParts.length > 0) {
        blocks = createBlocksFromEntities(usecaseData.scriptParts);
      } else if (usecaseData.finalScript) {
        const data = [
          {
            text: usecaseData.finalScript,
            order: 0,
            type: "normal",
          },
        ];
        blocks = createBlocksFromEntities(data);
      } else {
        blocks = rawContent;
      }
      const contentState = convertFromRaw(blocks);

      // Update the editor state
      setEditorState(EditorState.createWithContent(contentState, decorator));
    }
  }, [usecaseData?.scriptParts]);

  const [selectedEntityType, setSelectedEntityType] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [scriptUpdate, setScriptUpdate] = useState();
  const [isModalOpen, setIsModalOpen] = useState("");
  const [file, setFile] = useState("");
  const [fileLoading, setFileLoading] = useState(false);
  const [selectedTexts, setselectedTexts] = useState(false);
  const [userSelectedVideo, setUserSelectedVideo] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [showRemove, setshowRemove] = useState(false);
  const [isEntityAtSelection, setIsEntityAtSelection] = useState(false);
  const [form] = Form.useForm();
  const editorRef = useRef(null);
  const rightColRef = useRef(null); // Ref for the right-side column
  const buttonsRef = useRef(null); // Ref for the buttons

  useEffect(() => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const currentContent = editorState.getCurrentContent();
      const startKey = selection.getStartKey();
      const endKey = selection.getEndKey();
      const startOffset = selection.getStartOffset();
      const endOffset = selection.getEndOffset();

      const blockMap = currentContent.getBlockMap();
      const selectedBlocks = blockMap
        .skipUntil((_, key) => key === startKey)
        .takeUntil((_, key) => key === endKey)
        .concat([[endKey, blockMap.get(endKey)]]);

      const selectedText = selectedBlocks
        .map((block, key) => {
          const text = block.getText();
          if (key === startKey && key === endKey) {
            return text.slice(startOffset, endOffset);
          }
          if (key === startKey) {
            return text.slice(startOffset);
          }
          if (key === endKey) {
            return text.slice(0, endOffset);
          }
          return text;
        })
        .join("\n");

      const normalizedText = selectedText.replace(/\s+/g, " ").trim();
      const wordCount =
        normalizedText.length > 0 ? normalizedText.split(" ").length : 0;

      if (wordCount >= 6) {
        setselectedTexts(selectedText);
      } else {
        setselectedTexts(null);
      }
    } else {
      setselectedTexts(null);
    }
  }, [editorState]);

  const handleClickOutside = (event) => {
    console.log(event);

    // If click is outside the right column and buttons, close the column
    if (
      rightColRef.current &&
      !rightColRef.current.contains(event.target) && // Check click outside right column
      buttonsRef.current &&
      !buttonsRef.current.contains(event.target) //Check click outside buttons
    ) {
      setScriptUpdate();
      clearHighlight();
    }
  };

  const handleEntityTypeChange = (type) => {
    if (type === "SLIDE" && !pages) {
      setIsModalOpen(true);
      setInputValue(1);
    } else {
      // setEditorState(RichUtils.toggleInlineStyle(editorState, "HIGHLIGHT"));
      setSelectedEntityType(type);
      setScriptUpdate(type);
      setInputValue(type?.toUpperCase() === "SLIDE" ? 1 : "");
      getEntityAtSelection(editorState, type, true);
    }
  };

  const renderScriptContent = (type) => {
    if (type.toUpperCase() === "SUBTITLE") {
      return (
        <Flex
          vertical
          justify="flex-start"
          align="flex-start"
          gap={20}
          style={{ height: "100%" }}
        >
          <Input.TextArea
            value={inputValue}
            readOnly={videoStages.includes(status)}
            // autoFocus={true}
            onChange={(e) => setInputValue(e.target.value)}
            style={{ height: "100%", resize: "none" }}
            className="script-input"
            placeholder="Enter the keyword..."
          />
        </Flex>
      );
    } else if (type.toUpperCase() === "SLIDE") {
      return (
        <Flex
          vertical
          justify="flex-start"
          align="flex-start"
          gap={20}
          style={{ height: "100%", overflowY: "hidden" }}
        >
          <img
            style={{ maxWidth: "100%", overflowY: "hidden" }}
            src={
              slidePreview
                ? inputValue
                  ? slidePreview[inputValue]
                  : slidePreview[1]
                : ""
            }
            alt=""
          />
          <Select
            disabled={videoStages.includes(status)}
            value={inputValue ? inputValue : 1}
            style={{ height: 40, width: "100%", overflowY: "hidden" }}
            options={generateOptions(pages)}
            placeholder="Select slide number"
            onChange={(val) => {
              setInputValue(val);
            }}
          />
        </Flex>
      );
    } else if (type.toUpperCase() === "VIDEO") {
      return (
        <Flex
          vertical
          justify="flex-start"
          align="flex-start"
          gap={20}
          style={{ height: "100%" }}
        >
          {videos ? (
            <Row style={{ width: "100%" }} gutter={[10, 10]}>
              {videos
                ?.filter(
                  (video, index, self) =>
                    index === self.findIndex((v) => v.id === video.id) // Filters unique by `id`
                )
                .map((item) => (
                  <Col span={24}>
                    <Flex
                      vertical
                      gap={5}
                      style={{
                        padding: 10,
                        border: "1px solid",
                        borderRadius: 6,
                      }}
                    >
                      <img
                        src={item.image}
                        alt="img"
                        style={{
                          width: "100%",
                          height: "auto",
                          cursor: "pointer",
                        }}
                        onClick={() => handleThumbnailClick(item)}
                      />

                      <Button
                        type={
                          item?.id === selectedVideo?.id ? "primary" : "default"
                        }
                        onClick={() => setSelectedVideo(item)}
                      >
                        {item?.id === selectedVideo?.id ? "Selected" : "Select"}
                      </Button>
                    </Flex>
                  </Col>
                ))}
            </Row>
          ) : userSelectedVideo ? (
            <>
              <p>{inputValue}</p>
              <Flex
                vertical
                gap={5}
                style={{
                  padding: 10,
                  border: "1px solid",
                  borderRadius: 6,
                }}
              >
                <img
                  src={userSelectedVideo?.image}
                  alt="img"
                  style={{
                    width: "100%",
                    height: "auto",
                    cursor: "pointer",
                  }}
                  // onClick={() => handleThumbnailClick(item)}
                />
              </Flex>
            </>
          ) : (
            <Input.TextArea
              value={inputValue}
              // autoFocus={true}
              readOnly={videoStages.includes(status)}
              onChange={(e) => setInputValue(e.target.value)}
              style={{ height: "100%", resize: "none" }}
              className="script-input"
              placeholder="Enter the video prompt..."
            />
            // <video src={}></video>
          )}
          <Modal
            title="Video Player"
            visible={modalVisible}
            onCancel={handleCancel}
            footer={null}
          >
            {selectedVideo && (
              <>
                <video
                  src={selectedVideo?.video_files[0]?.link}
                  controls
                  autoPlay
                  style={{ width: "100%" }}
                />
                <div style={{ marginTop: 20, textAlign: "center" }}>
                  <Button
                    type={selectedVideo?.id ? "primary" : "default"}
                    style={{ width: "100px" }}
                    onClick={() => handleSelect(selectedVideo)}
                  >
                    Select
                  </Button>
                  <Button style={{ marginLeft: 10 }} onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </Modal>
        </Flex>
      );
    }
  };

  const handleThumbnailClick = (video) => {
    setSelectedVideo(video);
    setModalVisible(true);
  };

  const handleSelect = (item) => {
    setSelectedVideo(item);
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setSelectedVideo(null);
  };

  const handleCloseModal = () => {
    setAudioProfileModal(false);
  };

  const handleAudioProfileChange = (newProfile) => {
    setSelectedAudioProfile(newProfile);
  };

  const handleSubmit = (type, newState) => {
    let content;
    if (newState) {
      content = newState.getCurrentContent();
    } else {
      content = editorState.getCurrentContent();
    }
    const draftData = convertToRaw(content);

    const blocksArray = draftData.blocks || [];
    const entityMap = draftData.entityMap;
    const entitiesArray = [];
    let fullText = ""; // Initialize variable to store the full text

    blocksArray?.forEach((block, blockIndex) => {
      const blockText = block.text;
      const blockLength = blockText.length;
      let i = 0;
      let lastOffset = 0;

      while (i < blockLength) {
        const entityRange = block.entityRanges.find(
          (range) => i >= range.offset && i < range.offset + range.length
        );

        if (entityRange) {
          // Add normal text before the entity
          if (lastOffset < entityRange.offset) {
            const normalText = blockText.slice(lastOffset, entityRange.offset);
            entitiesArray.push({
              text: normalText,
              type: "normal",
            });
            fullText += normalText; // Append normal text to fullText
          }

          // Add entity text
          const entity = entityMap[entityRange.key];
          const entityData = entity.data;
          let data;

          if (entity.type.toLowerCase() === "subtitle") {
            data = { subtitleText: entityData.text };
          } else if (entity.type.toLowerCase() === "video") {
            data = {
              promptText: entityData.text,
              stockVideoData: entityData.video,
            };
          } else {
            data = { slideNumber: entityData.text };
          }

          const entityText = blockText.slice(
            entityRange.offset,
            entityRange.offset + entityRange.length
          );

          entitiesArray.push({
            text: entityText,
            type: entity.type.toLowerCase(),
            ...data,
          });
          fullText += entityText; // Append entity text to fullText

          // Update the last offset
          lastOffset = entityRange.offset + entityRange.length;
          i += entityRange.length; // Skip to the end of the entity
        } else {
          i++; // Just move to the next character if no entity
        }
      }

      // Add remaining normal text after the last entity
      if (lastOffset < blockLength) {
        const remainingText = blockText.slice(lastOffset);
        entitiesArray.push({
          text: remainingText,
          type: "normal",
        });
        fullText += remainingText; // Append remaining text to fullText
      }

      // Append '\n' to the last item for the block, if not the last block
      if (blockIndex < blocksArray.length - 1 && entitiesArray.length > 0) {
        entitiesArray[entitiesArray.length - 1].text += "\n\n"; // Append line break to the last entity
        fullText += "\n\n"; // Append line break to fullText
      }
    });

    // Merge consecutive normal entities
    const mergedEntities = [];
    entitiesArray.forEach((entity) => {
      if (
        mergedEntities.length > 0 &&
        mergedEntities[mergedEntities.length - 1].type === "normal" &&
        entity.type === "normal"
      ) {
        // Merge with the previous entity
        mergedEntities[mergedEntities.length - 1].text += entity.text;
      } else {
        // Add as a new entity
        mergedEntities.push(entity);
      }
    });

    // Add index key to each entity
    const indexedEntities = mergedEntities.map((entity, index) => ({
      ...entity,
      order: index,
    }));

    if (fullText) {
      // console.log(indexedEntities);

      updateScriptParts(indexedEntities, fullText, type);
    } else {
      NotificationInstance.info({
        message: "Please Enter a script",
      });
    }
  };

  const updateScriptParts = (data, script, type) => {
    setgenerating(type === "save" ? "save" : "submit");
    updateUseCase(useCaseId, { finalScript: script })
      .then((res) => {
        setStatus("LESSON_SCRIPT_SUBMITTED");
        setUsecaseData((prev) => ({ ...prev, finalScript: script }));
        updateUsecaseScript(useCaseId, {
          scriptParts: data,
          type: "LESSON",
          model: model ? model : "heygen",
        })
          .then((res) => {
            setUsecaseData((prev) => ({
              ...prev,
              audioData: [],
              scriptParts: res?.data?.scriptParts,
            }));
            setScriptParts(res?.data?.scriptParts);
            if (res?.data?.scriptParts?.length <= 3) {
              setVoicePreview(false);
            } else {
              setVoicePreview(true);
            }
            if (type !== "save") {
              setCurrent((prev) => prev + 1);
            } else {
              NotificationInstance.success({
                message: "Progress saved successfully",
              });
            }
            setgenerating(false);
          })
          .catch((err) => {
            setgenerating(false);
          });
      })
      .catch((err) => {
        setgenerating(false);
      });
  };

  const onChange = (newEditorState) => {
    const currentContent = editorState.getCurrentContent(); // The current content
    const newContent = newEditorState.getCurrentContent();
    if (currentContent !== newContent) {
      setEditorChanged(true);
    }
    setEditorState(newEditorState);
    getEntityAtSelection(newEditorState);
  };

  const handleBeforeInput = () => {
    // If current stage is in videoStages, block typing (returning 'handled')
    if (videoStages.includes(status)) {
      return "handled"; // Prevents typing
    }

    return "not-handled"; // Allows typing
  };

  const hasEntityInSubsentence = (editorState) => {
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const startKey = selectionState.getStartKey();
    const endKey = selectionState.getEndKey();
    const startOffset = selectionState.getStartOffset();
    const endOffset = selectionState.getEndOffset();

    const blocks = contentState.getBlockMap();
    const entitiesInRange = [];
    let entityFound = false;

    // Flag to start processing blocks
    let withinSelection = false;

    // Iterate through all blocks
    blocks.forEach((block, key) => {
      if (key === startKey) withinSelection = true; // Start block
      if (!withinSelection) return; // Skip blocks before startKey

      const blockStartOffset = key === startKey ? startOffset : 0;
      const blockEndOffset = key === endKey ? endOffset : block.getLength();

      // Iterate through the block's offsets in the range
      for (let offset = blockStartOffset; offset < blockEndOffset; offset++) {
        const entityKey = block.getEntityAt(offset);
        if (entityKey) {
          const entityInstance = contentState.getEntity(entityKey);

          // If type is specified, check for a matching type
          if (entityInstance.getType() !== "normal") {
            entityFound = true;
            entitiesInRange.push({
              key: entityKey,
              type: entityInstance.getType(),
              mutability: entityInstance.getMutability(),
              data: entityInstance.getData(),
              offset,
              blockKey: key,
            });
          }
        }
      }

      if (key === endKey) withinSelection = false; // End block
    });

    // Update the flag for entity presence
    if (entityFound) {
      setIsEntityAtSelection(true);
      return {
        found: true,
        entities: entitiesInRange,
      };
    }

    setIsEntityAtSelection(false);
    return { found: false };
  };

  const getEntityAtSelection = (editorState, type, add) => {
    const selectionState = editorState.getSelection();
    const selectionKey = selectionState.getStartKey();
    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(selectionKey);
    const entityKey = block.getEntityAt(selectionState.getStartOffset());
    const isEntity = hasEntityInSubsentence(editorState);

    if (entityKey && isEntity) {
      const entityInstance = contentState.getEntity(entityKey);
      const entityInfo = {
        type: entityInstance.getType(),
        mutability: entityInstance.getMutability(),
        data: entityInstance.getData(),
      };
      console.log(entityKey, entityInfo);

      setshowRemove(entityInstance.getData().text);
      if (
        entityInstance.getType().toLowerCase() === "video" &&
        entityInstance.getData().text
      ) {
        setUserSelectedVideo(entityInstance.getData().video);
      } else {
        setUserSelectedVideo();
      }
      if (type) {
        if (entityInstance.getType() === type) {
          setInputValue(entityInstance.getData().text);
        } else {
          setInputValue(type?.toUpperCase() === "SLIDE" ? 1 : "");
        }
      } else {
        setInputValue(entityInstance.getData().text);
      }

      setSelectedEntityType(type ? type : entityInstance.getType());
      setScriptUpdate(
        entityInstance.getType() !== "normal"
          ? type
            ? type
            : entityInstance.getType()
          : type
      );
    } else if (!add) {
      setScriptUpdate("normal");
      // console.log("No entity present at current selection!");
    }
  };

  function getEntityStrategy(mutability) {
    return function (contentBlock, callback, contentState) {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        if (entityKey === null) {
          return false;
        }

        return contentState.getEntity(entityKey).getMutability() === mutability;
      }, callback);
    };
  }
  const fetchStockVideos = (part, promptText) => {
    setVideoLoading(true);
    getStockVideos(useCaseId, { partTexts: [part], promptTexts: promptText })
      .then((res) => {
        setVideos(res.data);
        setVideoLoading(false);
      })
      .catch((err) => {
        setVideoLoading(false);
      });
  };

  const setEntityAtSelection = (type) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const block = contentState?.getBlockForKey(selectionState.getAnchorKey());
    const entityKey = block?.getEntityAt(selectionState.getStartOffset());
    if (entityKey) {
      block.findEntityRanges(
        (character) => character.getEntity() === entityKey,
        (start, end) => {
          const entitySelection = selectionState.merge({
            anchorOffset: start,
            focusOffset: end,
          });
          const entity = contentState?.getEntity(entityKey);
          const entityType = entity?.getType();
          const selectedText = block.getText().slice(start, end);
          setSubtitleText(selectedText);
          if (type.toUpperCase() === "VIDEO" && !videos) {
            if (inputValue) {
              setselectedState(selectionState);
              fetchStockVideos(selectedText, inputValue);
            } else {
              NotificationInstance.info({
                message: "Please enter a video prompt",
              });
            }
          } else if (type === "VIDEO") {
            if (!selectedVideo) {
              NotificationInstance.info({
                message: "Please select a video",
              });
              return;
            }
            let newContentState = contentState.createEntity(type, "SEGMENTED", {
              text: inputValue,
              video: selectedVideo,
            });
            const newEntityKey = newContentState.getLastCreatedEntityKey();
            newContentState = Modifier.applyEntity(
              newContentState,
              entityType !== "normal" ? entitySelection : selectionState,
              newEntityKey
            );
            const newEditorState = EditorState.push(
              editorState,
              newContentState,
              "apply-entity"
            );
            onChange(newEditorState);
            setScriptUpdate();
            setVideos();
            setUserSelectedVideo(false);
            focusEditorAtEnd(newEditorState);
            setEditorChanged(true);
            setInputValue();
            setSelectedVideo();
            handleSubmit("save", newEditorState);
          } else {
            if (!inputValue) {
              NotificationInstance.info({
                message: "Please enter a keyword",
              });
              return;
            }
            setselectedState(selectionState);

            let newContentState = contentState.createEntity(type, "SEGMENTED", {
              text: inputValue,
            });
            const newEntityKey = newContentState.getLastCreatedEntityKey();
            newContentState = Modifier.applyEntity(
              newContentState,
              entityType !== "normal" ? entitySelection : selectionState,
              newEntityKey
            );
            const newEditorState = EditorState.push(
              editorState,
              newContentState,
              "apply-entity"
            );
            setEditorState(newEditorState);
            setScriptUpdate();
            focusEditorAtEnd(newEditorState);
            setEditorChanged(true);
            setInputValue();
            handleSubmit("save", newEditorState);
          }
        }
      );
    } else {
      const selectedText = contentState
        .getBlockForKey(selectionState.getAnchorKey())
        .getText()
        .slice(selectionState.getStartOffset(), selectionState.getEndOffset());

      setSubtitleText(selectedText);
      if (type.toUpperCase() === "VIDEO" && !videos) {
        if (inputValue) {
          setselectedState(selectionState);
          fetchStockVideos(selectedText, inputValue);
        } else {
          NotificationInstance.info({
            message: "Please enter a video prompt",
          });
        }
      } else if (type === "VIDEO") {
        if (!selectedVideo) {
          NotificationInstance.info({
            message: "Please select a video",
          });
          return;
        }
        let newContentState = contentState.createEntity(type, "SEGMENTED", {
          text: inputValue,
          video: selectedVideo,
        });
        const entityKey = newContentState.getLastCreatedEntityKey();
        newContentState = Modifier.applyEntity(
          newContentState,
          selectedState,
          entityKey
        );
        const newEditorState = EditorState.push(
          editorState,
          newContentState,
          "apply-entity"
        );
        onChange(newEditorState);
        setScriptUpdate();
        setVideos();
        setUserSelectedVideo(false);
        focusEditorAtEnd(newEditorState);
        setEditorChanged(true);
        setInputValue();
        setSelectedVideo();
        handleSubmit("save", newEditorState);
      } else {
        if (!inputValue) {
          NotificationInstance.info({
            message: "Please enter a keyword",
          });
          return;
        }
        setselectedState(selectionState);

        let newContentState = contentState.createEntity(type, "SEGMENTED", {
          text: inputValue,
        });
        const entityKey = newContentState.getLastCreatedEntityKey();
        newContentState = Modifier.applyEntity(
          newContentState,
          selectionState,
          entityKey
        );
        const newEditorState = EditorState.push(
          editorState,
          newContentState,
          "apply-entity"
        );
        onChange(newEditorState);
        setScriptUpdate();
        focusEditorAtEnd(newEditorState);
        setEditorChanged(true);
        setInputValue();
        handleSubmit("save", newEditorState);
      }
    }
  };

  const focusEditorAtEnd = (editorState) => {
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();
    const lastBlock = blockMap.last();
    const lastBlockKey = lastBlock.getKey();
    const lastBlockLength = lastBlock.getLength();

    const selection = SelectionState.createEmpty(lastBlockKey).merge({
      anchorOffset: lastBlockLength,
      focusOffset: lastBlockLength,
    });

    const newEditorState = EditorState.forceSelection(editorState, selection);

    setEditorState(newEditorState);
  };
  const removeEntitiesInSubsentence = () => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const startKey = selectionState.getStartKey();
    const startOffset = selectionState.getStartOffset();
    const endKey = selectionState.getEndKey();
    const endOffset = selectionState.getEndOffset();

    // Keep track of processed entities to avoid redundant operations
    const processedEntities = new Set();

    let newContentState = contentState;

    // Iterate through all blocks
    contentState.getBlockMap().forEach((block) => {
      const blockKey = block.getKey();

      // Check if the block is within the selected range
      if (
        (blockKey === startKey && blockKey === endKey) ||
        (blockKey === startKey && blockKey !== endKey) ||
        (blockKey !== startKey && blockKey === endKey) ||
        (blockKey !== startKey && blockKey !== endKey)
      ) {
        const blockStartOffset = blockKey === startKey ? startOffset : 0;
        const blockEndOffset =
          blockKey === endKey ? endOffset : block.getLength();

        // Iterate over the block's range and find entities
        for (let offset = blockStartOffset; offset < blockEndOffset; offset++) {
          const entityKey = block.getEntityAt(offset);

          if (entityKey && !processedEntities.has(entityKey)) {
            // Add the entityKey to the processed set
            processedEntities.add(entityKey);

            // Iterate through all ranges of this entity in all blocks
            // eslint-disable-next-line no-loop-func
            contentState.getBlockMap().forEach((innerBlock) => {
              innerBlock.findEntityRanges(
                (character) => character.getEntity() === entityKey,
                (start, end) => {
                  const rangeToClear = SelectionState.createEmpty(
                    innerBlock.getKey()
                  ).merge({
                    anchorOffset: start,
                    focusOffset: end,
                  });

                  // Remove the entity from this range
                  newContentState = Modifier.applyEntity(
                    newContentState,
                    rangeToClear,
                    null
                  );
                }
              );
            });
          }
        }
      }
    });

    // Push the updated content state to the editor state
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "apply-entity"
    );
    onChange(newEditorState);
  };

  const removeEntityAtSelection = () => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const startKey = selectionState.getStartKey();
    const block = contentState.getBlockForKey(startKey);
    const entityKey = block.getEntityAt(selectionState.getStartOffset());

    setVideos();
    setUserSelectedVideo(false);
    setScriptUpdate();

    if (entityKey) {
      let newContentState = contentState;
      contentState.getBlockMap().forEach((block) => {
        block.findEntityRanges(
          (character) => character.getEntity() === entityKey,
          (start, end) => {
            const rangeSelection = SelectionState.createEmpty(
              block.getKey()
            ).merge({
              anchorOffset: start,
              focusOffset: end,
            });
            newContentState = Modifier.applyEntity(
              newContentState,
              rangeSelection,
              null
            );
          }
        );
      });

      // Push the updated content state to the editor
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "apply-entity"
      );
      onChange(newEditorState);
    } else {
      // console.log("No entity present at current selection to remove!");
    }
  };

  const removeAllSlideEntities = () => {
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();

    let newContentState = contentState;

    // Iterate through each block
    blockMap.forEach((block) => {
      const blockKey = block.getKey();
      const blockText = block.getText();

      // Iterate through each character in the block
      for (let i = 0; i < blockText.length; i++) {
        const entityKey = block.getEntityAt(i);

        if (entityKey) {
          const entity = contentState.getEntity(entityKey);

          // If the entity is of type 'SLIDE', remove it by applying null
          if (entity.getType() === "SLIDE") {
            const selectionState = editorState.getSelection().merge({
              anchorKey: blockKey,
              anchorOffset: i,
              focusKey: blockKey,
              focusOffset: i + 1,
            });

            newContentState = Modifier.applyEntity(
              newContentState,
              selectedState,
              null
            );
          }
        }
      }
    });

    // Push the updated content state to the editor
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "apply-entity"
    );

    setEditorState(newEditorState);
  };

  const uploadFile = (formData) => {
    uploadUseCasePPT(useCaseId, formData)
      .then((res) => {
        // setloading(false);
        // setVideoId(res?.data?.videoId);
        if (file) {
          removeAllSlideEntities();
        }
        setSlidePreview(res?.data?.slidePreview);
        setPages(res?.data?.totalSlides);

        // setFile(res?.data?.presentationBlobName);
        setFileLoading(false);
        setIsModalOpen(false);
        NotificationInstance.success({
          message: "File uploaded successfully",
        });
        setSelectedEntityType("SLIDE");
        setScriptUpdate("SLIDE");
      })
      .catch((err) => {
        // setloading(false);
        console.log(err);

        NotificationInstance.error({ message: "File upload failed" });
      });
  };

  const clearHighlight = () => {
    // const currentStyle = editorState.getCurrentInlineStyle();
    // const newEditorState = currentStyle.has("HIGHLIGHT")
    //   ? RichUtils.toggleInlineStyle(editorState, "HIGHLIGHT") // Remove highlight if applied
    //   : RichUtils.toggleInlineStyle(editorState, "HIGHLIGHT"); // Add highlight if not applied
    // setEditorState(newEditorState);
  };

  const updateTitle = () => {
    updateUseCase(useCaseId, { title: title })
      .then((res) => {
        setTitleDisabled(true);
        setUsecaseData((prev) => ({ ...prev, title: title }));
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (usecaseData) {
      form.setFieldsValue(usecaseData);
      if (usecaseData.slidePreview) {
        setSlidePreview(usecaseData.slidePreview[0]);
        setPages(usecaseData.totalSlides);
        setFile(usecaseData?.presentationBlobName);
      }
    }
  }, [usecaseData]);

  const handleCopy = (e) => {
    const selectionState = editorState.getSelection();
    if (!selectionState.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = selectionState.getStartKey();
      const endKey = selectionState.getEndKey();
      const startOffset = selectionState.getStartOffset();
      const endOffset = selectionState.getEndOffset();

      const blockMap = contentState.getBlockMap();
      const selectedBlocks = blockMap
        .skipUntil((_, key) => key === startKey)
        .takeUntil((_, key) => key === endKey)
        .concat([[endKey, blockMap.get(endKey)]]);

      const plainText = selectedBlocks
        .map((block, key) => {
          const text = block.getText();
          if (key === startKey && key === endKey) {
            return text.slice(startOffset, endOffset);
          }
          if (key === startKey) {
            return text.slice(startOffset);
          }
          if (key === endKey) {
            return text.slice(0, endOffset);
          }
          return text;
        })
        .join("\n");

      if (e.clipboardData) {
        e.clipboardData.writeText(plainText);
      }
    }
  };

  return (
    <>
      <Row gutter={[10, 10]} className="usecase-script-container">
        <Col
          className="usecase-content-wrapper"
          span={scriptUpdate && scriptUpdate !== "normal" ? 17 : 24}
        >
          <Row className="usecase-content" align="center">
            <Col className="usecase-content-wrapper" span={24}>
              <Row>
                <Col className="usecase-header" span={24}>
                  {usecaseData?.title ? (
                    <CustomHeader
                      title={title || usecaseData?.title}
                      setTitleDisabled={setTitleDisabled}
                      titleDisabled={titleDisabled}
                      setTitle={setTitle}
                      handleEntityTypeChange={handleEntityTypeChange}
                      selectedTexts={selectedTexts}
                      buttonsRef={buttonsRef}
                      updateTitle={updateTitle}
                      status={status}
                      usecaseData={usecaseData}
                      isEntityAtSelection={isEntityAtSelection}
                      removeEntitiesInSubsentence={removeEntitiesInSubsentence}
                      setEditorChanged={setEditorChanged}
                      model={model}
                      setModel={setModel}
                    />
                  ) : (
                    <Skeleton.Input />
                  )}

                  {isModalOpen && (
                    <UploadVideoPresentation
                      isModalOpen={isModalOpen}
                      setIsModalOpen={setIsModalOpen}
                      handleCancel={() => setIsModalOpen(false)}
                      handleOk={uploadFile}
                      setFile={setFile}
                      file={file}
                      setloading={setFileLoading}
                      loading={fileLoading}
                    />
                  )}
                </Col>
              </Row>
              <Row align="center" className="usecase-container">
                <Col
                  ref={rightColRef}
                  span={20}
                  className="usecase-editor-wrapper"
                >
                  <Editor
                    ref={editorRef}
                    editorState={editorState}
                    onChange={onChange}
                    placeholder="Enter the script"
                    // readOnly={videoStages.includes(status)}
                    handleBeforeInput={handleBeforeInput}
                    handleKeyCommand={handleBeforeInput}
                    onCopy={(e) => handleCopy(e)}
                  />
                </Col>
              </Row>
              <Row>
                {usecaseData && (
                  <Col className="usecase-footer" span={24}>
                    <Flex>
                      {(type === "diy" || type === "chat") &&
                        generating !== "submit" && (
                          <Button
                            onClick={() => setCurrent((prev) => prev - 1)}
                          >
                            Back
                          </Button>
                        )}

                      <Flex flex={1} gap={20} justify="flex-end">
                        {(!audioStages.includes(status) || editorChanged) &&
                          generating !== "submit" && (
                            <Button onClick={() => setAudioProfileModal(true)}>
                              Select Audio Profile
                            </Button>
                          )}
                        {scriptStages.includes(status) && !editorChanged ? (
                          <Button
                            onClick={() => setCurrent((prev) => prev + 1)}
                          >
                            Next
                          </Button>
                        ) : editorChanged &&
                          scriptUpdateStages.includes(status) ? (
                          <Flex justify="flex-end" gap={10}>
                            {generating !== "submit" && (
                              <Popconfirm
                                overlayStyle={{ width: 400 }}
                                title="Script"
                                description="Updating the content will delete all the audios generated previously. Do you want to continue?"
                                onConfirm={() => {
                                  handleSubmit("save");
                                }}
                              >
                                <Button loading={generating === "save"}>
                                  {generating === "save"
                                    ? "Saving Script"
                                    : "Save"}
                                </Button>
                              </Popconfirm>
                            )}
                            <Popconfirm
                              overlayStyle={{ width: 400 }}
                              title="Script"
                              description="Updating the lesson content will delete the previously generated audios and regenerate them. Do you want to continue?"
                              onConfirm={() => {
                                handleSubmit();
                              }}
                            >
                              <Button
                                type="primary"
                                loading={generating === "submit"}
                              >
                                {generating === "submit"
                                  ? "Generating Audios"
                                  : "Confirm Script"}
                              </Button>
                            </Popconfirm>
                          </Flex>
                        ) : (
                          <Flex justify="flex-end" gap={10}>
                            {generating !== "submit" && (
                              <Button
                                loading={generating === "save"}
                                onClick={() => handleSubmit("save")}
                              >
                                {generating === "save"
                                  ? "Saving Script"
                                  : "Save"}
                              </Button>
                            )}
                            <Popconfirm
                              overlayStyle={{ width: 400 }}
                              title="Lesson Script"
                              description="Confirming the script will generate sample audios, where you can configure them. Do you want to continue?"
                              onConfirm={() => handleSubmit("final")}
                            >
                              <Button
                                type="primary"
                                loading={generating === "submit"}
                              >
                                {generating === "submit"
                                  ? "Generating Audios"
                                  : "Confirm Script"}
                              </Button>
                            </Popconfirm>
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        {scriptUpdate && scriptUpdate !== "normal" && (
          <Col className="usecase-content-wrapper" span={7} ref={rightColRef}>
            <Row className="usecase-content" align="center">
              <Col className="usecase-content-wrapper" span={24}>
                <Row>
                  <Col className="usecase-header" span={24}>
                    <Flex flex={1} justify="space-between" gap={10}>
                      <span className="usecase-header-title">
                        Add{" "}
                        {capitalizeInput(
                          scriptUpdate === "SUBTITLE" ? "Keyword" : scriptUpdate
                        )}
                      </span>
                      <Flex gap={10}>
                        {videos && (
                          <Button
                            onClick={() => {
                              setVideos(null);
                              setUserSelectedVideo(false);
                            }}
                          >
                            Clear
                          </Button>
                        )}
                        {pages !== null &&
                          pages !== undefined &&
                          scriptUpdate === "SLIDE" && (
                            <Button
                              onClick={() => {
                                setIsModalOpen(true);
                              }}
                            >
                              Change Slide
                            </Button>
                          )}
                        <Button
                          onClick={() => {
                            setScriptUpdate();
                            // clearHighlight();
                          }}
                        >
                          Close
                        </Button>
                      </Flex>
                    </Flex>
                  </Col>
                </Row>
                <Row align="center" className="usecase-container">
                  <Col span={24}>{renderScriptContent(scriptUpdate)}</Col>
                </Row>
                <Row>
                  <Col className="usecase-footer" span={24}>
                    <Flex flex={1} gap={20} justify="flex-end">
                      {scriptUpdate?.toLowerCase() === "video" &&
                      !videos &&
                      !userSelectedVideo ? (
                        <Button
                          loading={videoLoading}
                          htmlType="submit"
                          onClick={() => setEntityAtSelection(scriptUpdate)}
                        >
                          Get Stock Videos
                        </Button>
                      ) : userSelectedVideo &&
                        scriptUpdate?.toLowerCase() === "video" ? (
                        <>
                          {inputValue && showRemove && (
                            <Button
                              htmlType="submit"
                              onClick={removeEntityAtSelection}
                            >
                              Remove {capitalizeInput(scriptUpdate)}
                            </Button>
                          )}
                          <Button
                            onClick={() => {
                              setVideos();
                              setUserSelectedVideo(false);
                              setInputValue();
                            }}
                          >
                            Change Video
                          </Button>
                        </>
                      ) : (
                        <>
                          {showRemove && (
                            <Button
                              htmlType="submit"
                              onClick={removeEntityAtSelection}
                            >
                              Remove{" "}
                              {capitalizeInput(
                                scriptUpdate === "SUBTITLE"
                                  ? "KEYWORD"
                                  : scriptUpdate
                              )}
                            </Button>
                          )}
                          <Button
                            htmlType="submit"
                            onClick={() => setEntityAtSelection(scriptUpdate)}
                          >
                            Save{" "}
                            {capitalizeInput(
                              scriptUpdate === "SUBTITLE"
                                ? "KEYWORD"
                                : scriptUpdate
                            )}
                          </Button>
                        </>
                      )}
                    </Flex>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      {audioProfileModal && (
        <AudioProfileModal
          visible={audioProfileModal}
          onClose={handleCloseModal}
          onSelect={handleAudioProfileChange}
          audioProfiles={audioProfiles}
          selectedAudio={selectedAudioProfile}
        />
      )}
    </>
  );
};

export default ScriptUpdation;
