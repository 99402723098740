import { Button, Col, Flex, Input, Row, Skeleton, Upload } from "antd";
import React, { useEffect, useRef, useState } from "react";
import styles from "./chatTemplate.module.css";
import { CloudUploadOutlined, FileOutlined } from "@ant-design/icons";
import { ref, limitToLast, onChildAdded, endAt } from "firebase/database";
import { ReactComponent as Send } from "../../assets/images/send.svg";
import { ReactComponent as Down } from "../../assets/images/scroll.svg";
import { dataBase } from "../firebase/firebase";
import {
  createCourseWithChat,
  getChatResponseForCourseCreation,
  getVideoUseCaseById,
  sendLessonChat,
} from "../../api/adminService";
import { query } from "firebase/firestore";
import {
  getGeneralMessage,
  getLatestGeneralMessage,
} from "../../api/profileServices";
import NotificationInstance from "../../services/notificationServices";
import {
  getVideoChatMessage,
  updateVideoChatMessage,
} from "../../api/chatServices";
import { getProjectById } from "../../api/projectServices";
import { timeCreatedOn } from "../../utils/format";
import { documentStages } from "../../utils/constant";

const ChatTemplate = ({
  type,
  useCaseId,
  useCaseData,
  next,
  setUsecaseData,
}) => {
  const [file, setFile] = useState({
    file: null,
    updated: false,
  });
  const [fileList, setFileList] = useState([]);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [chat, setChat] = useState([]);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState();
  const [total, setTotal] = useState();
  const [projectData, setProjectData] = useState();
  const [projectLoading, setProjectLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [chatLoaded, setChatLoaded] = useState(false);

  const container = useRef();

  const scrollToBottom = () => {
    if (container.current) {
      container.current.scrollTop = container.current.scrollHeight;
    }
  };

  const updateMessages = (newMessages, reverese) => {
    setChat((prevMessages) => {
      const messageIds = new Set(prevMessages.map((msg) => msg._id));
      const uniqueNewMessages = newMessages?.filter(
        (msg) => !messageIds.has(msg._id)
      );

      if (reverese) {
        return [...prevMessages, ...uniqueNewMessages];
      } else {
        return [...uniqueNewMessages, ...prevMessages];
      }
    });
  };

  useEffect(() => {
    let unsubscribe;
    if (useCaseData?.threadCtxId && !useCaseData?.finalScript) {
      const chatRef = query(
        ref(dataBase, `templateBasedChat/${useCaseData?.threadCtxId}`),
        endAt,
        limitToLast(1)
      );
      unsubscribe = onChildAdded(chatRef, (snapshot) => {
        const data = snapshot.val();
        console.log({ data });

        if (data) {
          setStatus(data.status);
          if (data?._id) {
            getLatestGeneralMessage(useCaseData?.courseChatChannelId, data._id)
              .then((res) => {
                console.log({ here: res.data });
                updateMessages([res.data], true);
                setLoading(false);
              })
              .catch((err) => {});
          }
          if (data.status === "COMPLETED") {
            getVideoUseCaseById(useCaseId)
              .then((res) => {
                setUsecaseData(res?.data);
                // next();
              })
              .catch((err) => {});
          }
          if (data.status === "STRUCTURE_CREATION_FAILED") {
            setUsecaseData((prev) => ({
              ...prev,
              state: "STRUCTURE_CREATION_FAILED",
            }));
          }
        }
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [
    useCaseData.threadCtxId,
    useCaseData.courseChatChannelId,
    useCaseData.finalScript,
    type,
    useCaseId,
  ]);

  useEffect(() => {
    getVideoChatMessage(
      useCaseData?.courseChatChannelId,
      useCaseId,
      useCaseData?.threadCtxId,
      20,
      pageNo
    )
      .then((res) => {
        setTotal(res?.info?.totalCount);
        setChatLoading(false);
        setChatLoaded(true);
        updateMessages(res.data.reverse(), false);
      })
      .catch((error) => console.log(error));
  }, [
    useCaseData.courseChatChannelId,
    useCaseData.threadCtxId,
    pageNo,
    useCaseId,
  ]);

  useEffect(() => {
    let pageNo = 1;
    const containerdiv = container?.current;
    if (containerdiv) {
      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = containerdiv;
        const threshold = 10;
        const atBottom = scrollHeight - scrollTop <= clientHeight + threshold;
        const atTop = scrollTop === 0;
        setShowScrollButton(!atBottom);

        if (atTop && pageNo * 20 < total) {
          pageNo = pageNo + 1;
          setPageNo((prev) => prev + 1);
          setChatLoading(true);
        }
      };
      containerdiv?.addEventListener("scroll", handleScroll);
      return () => {
        if (containerdiv) {
          containerdiv?.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [container, total]);

  useEffect(() => {
    if (container.current) {
      const scrollHeight = container.current.scrollHeight;
      container.current.scrollTo({
        top: scrollHeight,
        behaviour: "auto",
      });
    }
  }, []);

  useEffect(() => {
    const containerDiv = container.current;

    if (containerDiv && !showScrollButton) {
      const observer = new MutationObserver(() => {
        scrollToBottom();
      });

      observer.observe(containerDiv, {
        childList: true,
        subtree: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [showScrollButton]);

  useEffect(() => {
    if (
      useCaseData?.projectId &&
      useCaseData.state === "TEMPLATE_SELECTION_PENDING"
    ) {
      setProjectLoading(true);
      getProjectById(useCaseData?.projectId)
        .then((res) => {
          setProjectData(res?.data);
          setProjectLoading(false);
        })
        .catch((err) => {
          setProjectLoading(false);
        });
    }
  }, [useCaseData]);

  const handleChange = async (info) => {
    const file = info.file;
    const isSupportedFileType =
      file.type === "application/msword" || // DOC
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || // DOCX
      file.type === "text/plain" || // TXT
      file.type === "application/pdf"; // PDF
    if (!isSupportedFileType) {
      NotificationInstance.warning({
        message: `Please upload a file with supported file type!`,
      });
      return;
    }
    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 100) {
      NotificationInstance.warning({
        message: "File must be smaller than 100MB!",
      });
      return;
    }
    setFile({
      file: info.file,
      updated: true,
    });
    setFileList(info.fileList);
  };
  const handleRemove = () => {
    setFile(null);
    setFileList([]);
  };

  const handleSend = async (message) => {
    if (!message.trim()) {
      NotificationInstance.warning({ message: "Please enter a message" });
      return;
    }

    const newMessage = {
      _id: Date.now(),
      message: message.trim(),
      senderDetails: { userType: "STAFF" },
      fileName: file?.file?.name || "",
    };
    setLoading(true);
    setChat((prevChat) => [...prevChat, newMessage]);
    setMessage("");
    scrollToBottom();
    try {
      const data = {
        message: message.trim(),
        threadCtxId: useCaseData?.threadCtxId,
        file: file?.file?.originFileObj,
      };

      await updateVideoChatMessage({
        useCaseId,
        channel: useCaseData?.courseChatChannelId,
        data: data,
      });
      setMessage("");
      setFile({ file: null, updated: false });
      setFileList([]);
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };

  const updateFile = async (item) => {
    const newMessage = {
      _id: Date.now(),
      message: item?.name,
      senderDetails: { userType: "STAFF" },
      isProjectFile: true,
    };
    setChat((prevChat) => [...prevChat, newMessage]);
    setLoading(true);
    try {
      const data = {
        projectFileId: item?.id,
        threadCtxId: useCaseData?.threadCtxId,
      };
      setUsecaseData((prev) => ({ ...prev, state: "TEMPLATE_CONFIRMED" }));

      await updateVideoChatMessage({
        useCaseId,
        channel: useCaseData?.courseChatChannelId,
        data: data,
      });
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };

  const customRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const beforeUpload = (file) => {
    const isSupportedFileType =
      file.type === "application/msword" || // DOC
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || // DOCX
      file.type === "text/plain" || // TXT
      file.type === "application/pdf"; // PDF

    const fileSize = file.size / 1024 / 1024;

    if (!isSupportedFileType) {
      return false;
    }

    if (fileSize > 100) {
      return false;
    }

    return true;
  };
  const formatMessage = (message) => {
    return message
      ?.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
      ?.replace(/\n/g, "<br>")
      ?.replace(/__(.*?)__/g, "<em>$1</em>")
      ?.replace(
        /<INFO_START>(.*?)<INFO_END>/g,
        '<p style="padding: 10px; background: #fff; border-radius:10px; font-weight:500"><i>$1</i></p>'
      );
  };

  const templateStatus = [
    "TEMPLATE_SELECTION_PENDING",
    "TEMPLATE_SELECTION_FAILED",
    "TEMPLATE_CONFIRMED",
  ];

  return (
    <Row align="center" className={styles["chat-template"]}>
      <Col span={24} className={styles["chat-template-container"]}>
        <Flex flex={1} vertical className={styles["chat-container-wrapper"]}>
          <Row ref={container} className={styles["chat-container-row"]}>
            <Col span={24} className={styles["chat-container-col"]}>
              <Flex vertical flex={1} className={styles["chat-container"]}>
                {chatLoading && chatLoaded && (
                  <Flex vertical gap={20} style={{ height: "100%" }}>
                    {Array.from({ length: 2 }).map((_, index) => (
                      <Flex
                        className={`chat-message ${
                          index % 2 === 0 ? "my-message" : "bot-message"
                        }`}
                        key={index}
                      >
                        <Flex
                          flex={1}
                          vertical
                          gap={12}
                          align={`${index % 2 === 0 ? "" : "flex-end"}`}
                        >
                          <Skeleton.Button
                            block
                            active
                            style={{ height: 60 }}
                          />
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                )}
                {chatLoaded ? (
                  chat.map((item) => (
                    <Flex flex={item?.fileName ? 1 : ""} vertical gap={5}>
                      {item?.isProjectFile ? (
                        <Flex justify="flex-end">
                          <div className={styles["uploaded-file"]}>
                            <FileOutlined className={styles["doc-file-icon"]} />{" "}
                            <i>{item?.message}</i>
                          </div>
                        </Flex>
                      ) : (
                        <Flex
                          vertical
                          className={`${styles["chat-message"]} ${
                            item.senderDetails.userType === "STAFF"
                              ? styles["my-message"]
                              : styles["bot-message"]
                          }`}
                        >
                          <p
                            dangerouslySetInnerHTML={{
                              __html: formatMessage(item.message),
                            }}
                          />
                        </Flex>
                      )}
                    </Flex>
                  ))
                ) : (
                  <Flex vertical gap={20} style={{ height: "100%" }}>
                    {/* {Array.from({ length: 5 }).map((_, index) => (
                      <Flex
                        className={`chat-message ${
                          index % 2 === 0 ? "my-message" : "bot-message"
                        }`}
                        key={index}
                      >
                        <Flex
                          flex={1}
                          vertical
                          gap={12}
                          align={`${index % 2 === 0 ? "" : "flex-end"}`}
                        >
                          <Skeleton.Button block active style={{height:60}}/>
                        </Flex>
                      </Flex>
                    ))} */}
                    <div
                      className="video-loader"
                      style={{
                        width: "100%",
                        marginTop: "20px",
                        height: "100%",
                      }}
                    >
                      <div className="loader"></div>
                    </div>
                  </Flex>
                )}
                {useCaseData?.state === "TEMPLATE_SELECTION_PENDING" &&
                  projectData?.projectFiles
                    ?.filter(
                      (item) =>
                        item.status ===
                          documentStages.TEXT_EXTRACTION_COMPLETED ||
                        item.status ===
                          documentStages.CONTEXT_BUILD_COMPLETED ||
                        item.status === documentStages.CONTEXT_BUILD_FAILED
                    )
                    .map((item) => (
                      <Flex
                        gap={10}
                        className={styles["project-list"]}
                        onClick={() => updateFile(item)}
                      >
                        <Flex
                          style={{ width: "100%", height: "fit-content" }}
                          gap={20}
                        >
                          <span className="list-type">{item?.type}</span>
                          <Flex
                            vertical
                            className="list-detail"
                            align="flex-start"
                            justify="center"
                          >
                            <span className="list-item">
                              {item?.name || item?.title}
                            </span>
                            <span>
                              Uploaded on {timeCreatedOn(item?.createdAt)}
                            </span>
                          </Flex>
                        </Flex>
                      </Flex>
                    ))}
                {loading && (
                  <div className="chat-body">
                    <div className="empty-chat-loader">
                      <div className="chat-loader"></div>
                    </div>
                  </div>
                )}
              </Flex>
              <button
                className={`${styles["scroll-button"]} ${
                  showScrollButton ? styles["visible"] : ""
                }`}
                onClick={scrollToBottom}
              >
                <Down />
              </button>
            </Col>
          </Row>
        </Flex>
        {useCaseData?.state !== "TEMPLATE_SELECTION_PENDING" &&
          useCaseData?.state !== "STRUCTURE_CREATION_FAILED" && (
            <>
              {templateStatus.includes(useCaseData.state) ? (
                <Flex
                  className={styles["chat-template-input-container"]}
                  vertical
                  gap={8}
                >
                  <Input.TextArea
                    placeholder="Enter message"
                    autoSize={{ minRows: 1, maxRows: 6 }}
                    className={styles["chat-template-input"]}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e) => {
                      if (
                        e.key === "Enter" &&
                        !e.shiftKey &&
                        e.target.value.trim() !== ""
                      ) {
                        e.preventDefault();
                        handleSend(e.target.value);
                        setMessage("");
                      } else if (e.key === "Enter" && e.shiftKey) {
                        e.preventDefault();
                        const start = e.target.selectionStart;
                        const end = e.target.selectionEnd;
                        const value = e.target.value;
                        e.target.value =
                          value.substring(0, start) +
                          "\n" +
                          value.substring(end);
                        e.target.selectionStart = e.target.selectionEnd =
                          start + 1;
                        setMessage(e.target.value);
                      }
                    }}
                  />
                  <Flex
                    justify="flex-end"
                    className={styles["chat-template-icons"]}
                  >
                    {/* <Upload
                    maxCount={1}
                    multiple={false}
                    onChange={handleChange}
                    onRemove={handleRemove}
                    customRequest={customRequest}
                    beforeUpload={beforeUpload}
                    fileList={fileList}
                    accept=".doc,.docx,.txt,.pdf"
                  >
                    <CloudUploadOutlined
                      className={styles["chat-template-icons"]}
                    />
                  </Upload> */}
                    <Send
                      onClick={() => handleSend(message)}
                      cursor={"pointer"}
                    />
                  </Flex>
                </Flex>
              ) : (
                <Flex justify="flex-end">
                  <Button onClick={next}>Next</Button>
                </Flex>
              )}
            </>
          )}
      </Col>
      {/* <Col span={7}></Col> */}
    </Row>
  );
};

export default ChatTemplate;
