import {
  Button,
  Checkbox,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Tooltip,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";
import { createAvatar, getMyAvatarsById } from "../../api/adminService";
import NotificationInstance from "../../services/notificationServices";
import {
  InboxOutlined,
  VideoCameraOutlined,
  CopyOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import VideoRecorder from "../../pages/avatars/videoRecorder";

const AddAvatarModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  avatarId,
  handleUpdation,
}) => {
  const { Dragger } = Upload;
  const [form] = Form.useForm();
  const [isUpdated, setIsUpdated] = useState(false);
  const [isRecording, setIsRecorderON] = useState(false);
  const [video, setVideo] = useState();
  const [isUpdating, setIsUpdating] = useState(false);

  const onModalClose = () => {
    setIsRecorderON(false);
    setIsUpdated(false);
    setVideo();
    handleCancel();
    form.resetFields();
  };
  const handleChange = async (info) => {
    if (!info.file.type.match(/video\/(mp4|avi|mkv|mov|x-matroska)/)) {
      return;
    }
    const extension = info?.file?.name?.slice(
      info?.file?.name?.lastIndexOf(".") + 1
    );
    console.log(info.file);
    const videoUrl = URL.createObjectURL(info.file.originFileObj);

    setVideo({ data: info.file?.originFileObj, type: "upload", url: videoUrl });
  };

  const customRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const beforeUpload = (file) => {
    const isvideo = file.type.match(/video\/(mp4|avi|mkv|mov|x-matroska)/);
    if (!isvideo) {
      NotificationInstance.warning({
        message: "Please upload only MP4, AVI, MKV or MOV video files!",
      });
      return false;
    }

    return true;
  };

  const handleCreateAvatar = () => {
    if (!video) {
      NotificationInstance.info({ message: "Please upload or record a video" });
      return;
    }
    form
      .validateFields()
      .then((res) => {
        const { title } = res;
        if (video) {
          setIsUpdating(true);
          const formData = new FormData();
          formData.append("title", title);
          formData.append("avatarVideo", video.data);
          formData.append("model", "sync");
          createAvatar(formData)
            .then((res) => {
              setIsUpdating(false);
              console.log(res);
              const currentTime = new Date().toISOString();
              handleOk({
                _id: res?.data?.id,
                name: title,
                isDefault: false,
                createdAt: currentTime,
              });
              NotificationInstance.success({
                message: "Successfully created new avatar",
              });
              handleCancel();
            })
            .catch((err) => {
              setIsUpdating(false);
            });
        } else {
          NotificationInstance.info({
            message: "Please upload or record a video",
          });
        }
      })
      .catch((err) => {});
  };

  const handleUpdateAvatar = () => {
    form
      .validateFields()
      .then((values) => {
        handleUpdation(avatarId, {
          ...values,
          name: values?.title,
          title: values?.title,
        });
        handleCancel();
      })
      .catch((err) => {});
  };

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link);
  };

  useEffect(() => {
    if (avatarId) {
      setIsUpdating(true);
      getMyAvatarsById(avatarId)
        .then((res) => {
          form.setFieldsValue({
            title: res?.data?.name,
            isDefault: res?.data?.isDefault, // Bind 'isDefault' correctly
          });
          setIsUpdating(false);
          setVideo({
            url: res?.data?.sampleURL,
            isDefault: res?.data?.isDefault,
            state: res?.data?.state,
          });
        })
        .catch((err) => {});
    }
  }, [avatarId]);

  return (
    <Modal
      centered
      width={600}
      title={avatarId ? "Update Avatar" : "Add Avatar"}
      open={isModalOpen}
      onCancel={onModalClose}
      maskClosable={false}
      closable={!isUpdating}
      footer={() =>
        !isUpdating && (
          <div className="action-buttons">
            {!isRecording && !avatarId && (
              <>
                <Button
                  type="primary"
                  disabled={isUpdating}
                  onClick={handleCreateAvatar}
                >
                  Create
                </Button>
                {video && (
                  <Button
                    disabled={isUpdating}
                    className="discard-button"
                    onClick={() => setVideo()}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  disabled={isUpdating}
                  className="cancel-button"
                  onClick={onModalClose}
                >
                  Cancel
                </Button>
              </>
            )}
            {avatarId && (
              <>
                <Button
                  type="primary"
                  disabled={!isUpdated}
                  onClick={handleUpdateAvatar}
                >
                  Update
                </Button>
                <Button
                  type="text"
                  disabled={isUpdating}
                  className="cancel-button"
                  onClick={onModalClose}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        )
      }
    >
      {isUpdating ? (
        <div
          className="video-loader"
          style={{ width: "100%", marginTop: "20px", height: 400 }}
        >
          <div className="loader"></div>
        </div>
      ) : (
        <div className="title-input-wrapper">
          <Form
            form={form}
            onFieldsChange={() => setIsUpdated(true)}
            layout="vertical"
            className="add-user"
          >
            <Form.Item
              name="title"
              required={false}
              label="Title"
              rules={[
                {
                  required: true,
                  message: "Please enter title",
                },
              ]}
            >
              <Input placeholder="Enter the title" />
            </Form.Item>
            {avatarId && !video?.isDefault && video?.state === "COMPLETED" && (
              <Form.Item
                name="isDefault"
                required={false}
                label=""
                valuePropName="checked"
              >
                <Checkbox>Set As Default</Checkbox>
              </Form.Item>
            )}
            {avatarId && video?.url && (
              <Flex vertical gap={10}>
                <div style={{ width: "100%", height: "400px" }}>
                  <iframe
                    style={{ width: "100%", height: "400px" }}
                    src={video?.url}
                    controls
                    autoPlay
                    loop
                  />
                </div>
                <Flex gap={10} justify="flex-end">
                  <Tooltip title="Copied" trigger="click" placement="bottom">
                    {" "}
                    <Button
                      onClick={() => handleCopyLink(video?.url)}
                      style={{ marginRight: 8 }}
                    >
                      <CopyOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Open Link" placement="bottom">
                    <Button
                      onClick={() => window.open(video?.url, "_blank")}
                      style={{ marginRight: 8 }}
                    >
                      <LinkOutlined />
                    </Button>
                  </Tooltip>
                </Flex>
              </Flex>
            )}
            {isRecording && (
              <VideoRecorder
                setIsRecorderON={setIsRecorderON}
                setVideo={setVideo}
              />
            )}
            {!isRecording && !avatarId && (
              <Flex vertical gap={5}>
                <span>Video</span>
                {video ? (
                  <div style={{ width: "100%", maxHeight: "400px" }}>
                    <video
                      style={{ width: "100%", maxHeight: "400px" }}
                      src={video?.url}
                      controls
                      autoPlay
                      loop
                    />
                  </div>
                ) : (
                  <Flex gap={20}>
                    <Flex flex={1} justify="center">
                      <Dragger
                        listType="picture"
                        maxCount={1}
                        multiple={false}
                        onChange={handleChange}
                        customRequest={customRequest}
                        beforeUpload={beforeUpload}
                        showUploadList={false}
                        accept=".mp4,.avi,.mkv,.mov"
                      >
                        <Flex gap={5} vertical align="center" justify="center">
                          <InboxOutlined />
                          <p className="ant-upload-text">
                            Click or drag file to upload video
                          </p>
                        </Flex>
                      </Dragger>
                    </Flex>
                    {/* <h2>or</h2>
                    <Flex
                      flex={1}
                      gap={5}
                      vertical
                      className="record-video"
                      align="center"
                      justify="center"
                      onClick={() => setIsRecorderON(true)}
                    >
                      <VideoCameraOutlined />
                      <span className="ant-upload-text">Record video</span>
                    </Flex> */}
                  </Flex>
                )}
              </Flex>
            )}
          </Form>
        </div>
      )}
    </Modal>
  );
};

export default AddAvatarModal;
