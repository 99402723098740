import React, { useEffect } from "react";
import { Tabs } from "antd";
import MyAvatars from "./avatars";
import StudioAvatar from "./studioAvatar";
const onChange = (key) => {
  console.log(key);
};
const items = [
  {
    key: "1",
    label: "Instant Avatar (Beta)",
    children: <MyAvatars/>,
  },
  {
    key: "2",
    label: "Studio Avatar",
    children: <StudioAvatar />,
  },
];
const AvatarCollection = () => {
  return (
    <Tabs
      defaultActiveKey="1"
      items={items}
      onChange={onChange}
      className="avatar-tab"
    />
  );
};
export default AvatarCollection;
