import { Button, Checkbox, Divider, Drawer, Empty, Input, Select } from "antd";
import React, { useState } from "react";
import "./modal.css";
import { courseStageTypes } from "../../utils/constant";
import { getStatusLabel } from "../../utils/format";

const VideosFilterDrawer = ({
  isDrawerOpen,
  handleCancel,
  setfilter,
  typeFilter,
  stateFilter,
  setVideosPage,
}) => {
  const [type, setType] = useState(typeFilter ?? "");
  const [state, setState] = useState(stateFilter ?? []);
  const filterType = [
    { value: "TRAINING", label: "Training" },
    { value: "PERSONALIZED", label: "Personalized" },
    { value: "DIY", label: "DIY" },
    { value: "LESSON", label: "Lesson" },
    { value: "DEMO", label: "Demo" },
    { value: "TEMPLATE_BASED_TRAINING_VIDEO", label: "Template Based Training Video" }
  ];
  
  const onSubmit = () => {
    setfilter((prev) => ({
      type: type,
      state: state,
      sharedOnly: prev?.sharedOnly,
      active: type || state,
    }));
    setVideosPage(1);
    handleCancel();
  };
  const onModalClose = () => {
    handleCancel();
  };
  const onClearFilter = () => {
    setType("");
    setState([]);
    setfilter((prev) => ({
      type: "",
      state: [],
      active: false,
      sharedOnly: prev?.sharedOnly,
    }));
  };

  return (
    <Drawer
      width={450}
      title="Filter"
      open={isDrawerOpen}
      onClose={onModalClose}
      footer={
        <div className="action-buttons">
          <Button type="primary" onClick={onSubmit}>
            Apply Filter
          </Button>
          <Button className="cancel-button" onClick={onClearFilter}>
            Clear all
          </Button>
        </div>
      }
    >
      <div className="drawer-content-wrapper">
        <div className="type-filter">
          <h3>Type</h3>
          <div className="categories">
            <Checkbox.Group
              options={filterType}
              onChange={(value) => setType(value)}
              value={type}
            />
          </div>
          <Divider />
        </div>
        <div className="state-filter">
          <h3>State</h3>
          <Select
            value={state}
            showSearch
            mode="multiple"
            placeholder="Select states"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={Object.entries(courseStageTypes).map(([key, value]) => ({
              label: getStatusLabel(key), // You can use courseStageLabels to make the labels user-friendly
              value: value,
            }))}
            onChange={(value) => setState(value)}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default VideosFilterDrawer;
