import React, { useEffect, useState } from "react";
import "./DIYQuestions.css";
import { Button, Col, Flex, Form, Input, Row, Skeleton } from "antd";
import { endAt, limitToLast, onChildAdded, ref } from "firebase/database";
import { dataBase } from "../../firebase/firebase";
import { query } from "firebase/firestore";
import {
  getScriptQuestions,
  postScriptResult,
} from "../../../api/hubSpotService";
import { getVideoUseCaseById } from "../../../api/adminService";

const { TextArea } = Input;

const DIYQuestions = ({
  usecaseData,
  setCurrent,
  title,
  useCaseId,
  setUsecaseData,
  voicePreview,
}) => {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState();
  const [generating, setGenerating] = useState(false);
  const [form] = Form.useForm();
  const [error, setError] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [formUpdated, setFormUpdated] = useState(false);

  useEffect(() => {
    setLoading(true);
    getScriptQuestions()
      .then((res) => {
        setLoading(false);
        console.log(res);
        setQuestions(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleFinish = async () => {
    try {
      setGenerating(true);
      setError(null);
      const res = await form.validateFields();
      console.log(res);

      const answers = Object.keys(res).map((key) => ({
        questionId: key,
        answer: res[key],
      }));
      console.log(answers);
      const type = "DIY";
      const formData = { title, answers, type };
      const result = await postScriptResult(useCaseId, formData);
      console.log("backend result", result);
      setJobId(result.data.jobId);
      generateScript(result.data.jobId);
    } catch (err) {
      console.error(err);
      setGenerating(false);
      setError(err.message || "An error occurred");
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (usecaseData) {
      const answers = {};
      usecaseData?.userAnswers?.forEach((answer) => {
        answers[answer.questionId] = answer.answer;
      });
      form.setFieldsValue(answers);
    }
  }, [usecaseData]);

  const generateScript = (jobId) => {
    let unsubscribe;
    const channelRef = query(
      ref(dataBase, `scriptgen/${jobId}`),
      endAt,
      limitToLast(1)
    );

    unsubscribe = onChildAdded(channelRef, (snapshot) => {
      console.log("snapshot", snapshot);
      const message = snapshot.val();
      console.log("message ", message);
      if (message.status === "completed") {
        getVideoUseCaseById(jobId)
          // getScripts(jobId)
          .then((res) => {
            setUsecaseData((prev) => ({
              ...prev,
              finalScript: res?.data?.finalScript,
              userAnswers: res?.data?.userAnswers,
            }));
            console.log("response from firebase:", res);
            setGenerating(false);
            setCurrent((prev) => prev + 1);
            setFormUpdated(false);
          })
          .catch((err) => {
            console.error(err);
            setError(err.message || "Failed to get scripts");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  };

  return (
    <>
      {/* <Row>
        <Col className="usecase-header" span={24}>
          <span className="usecase-header-title">{usecaseData?.title}</span>
        </Col>
      </Row> */}
      <Row align="center" className="usecase-container">
        <Col span={20}>
          {loading ? (
            <Flex
              vertical
              gap={30}
              align="flex-start"
              className="question-form"
            >
              {Array.from({ length: 5 }).map((_, index) => (
                <Flex vertical style={{ width: "100%" }}>
                  <Skeleton
                    block
                    active
                    size={80}
                    style={{ width: "100%" }}
                    paragraph={{ rows: 1 }}
                  />
                  <Skeleton.Button
                    block
                    active
                    size={100}
                    style={{ width: "100%" }}
                  />
                </Flex>
              ))}
            </Flex>
          ) : (
            <Form
              form={form}
              layout="vertical"
              className="question-form"
              initialValues={usecaseData}
              onFieldsChange={() => setFormUpdated(true)}
            >
              <Flex vertical gap={10}>
                {questions?.map((question, index) => {
                  return (
                    <Form.Item
                      required={false}
                      key={index}
                      label={
                        <Flex vertical gap={6}>
                          <span className="index">
                            Question {question?.questionOrder} of{" "}
                            {questions?.length}
                          </span>
                          <span className="question">
                            {question?.questionText}
                          </span>
                        </Flex>
                      }
                      name={question?._id}
                      rules={[
                        {
                          required: true,
                          message: "This question is required",
                        },
                      ]}
                    >
                      <TextArea
                        disabled={generating}
                        rows={3}
                        autoSize={{ minRows: 6, maxRows: 6 }}
                        className="answer-input"
                        placeholder="Enter your answer here"
                      />
                    </Form.Item>
                  );
                })}
              </Flex>
            </Form>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="usecase-footer" span={24}>
          <Flex flex={1} gap={20} justify="flex-end">
            {usecaseData?.finalScript && (
              <Button onClick={() => setCurrent((prev) => prev + 1)}>
                Next
              </Button>
            )}
            {usecaseData && (!usecaseData?.finalScript || formUpdated) && (
              <Button
                htmlType="submit"
                onClick={handleFinish}
                loading={generating}
              >
                {generating ? "Generating Script" : "Generate Script"}
              </Button>
            )}
          </Flex>
        </Col>
      </Row>
    </>
  );
};

export default DIYQuestions;
