export const timeSinceLastSaved = (savedTime) => {
  const currentTime = new Date();
  const diffInMilliseconds = currentTime - savedTime;
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInDays > 0) {
    return `${diffInDays} day${diffInDays !== 1 ? "s" : ""} ago`;
  } else if (diffInHours > 0) {
    return `${diffInHours} hour${diffInHours !== 1 ? "s" : ""} ago`;
  } else {
    return `${diffInMinutes} min${diffInMinutes !== 1 ? "s" : ""} ago`;
  }
};
export const timeSinceLastUpdated = (savedTime) => {
  const currentTime = new Date();
  const parsedSavedTime = new Date(savedTime);

  if (isNaN(parsedSavedTime.getTime())) {
    // Handle invalid date format
    return "Invalid date";
  }

  const diffInMilliseconds = currentTime - parsedSavedTime;
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInDays > 0) {
    return `${diffInDays} day${diffInDays !== 1 ? "s" : ""} ago`;
  } else if (diffInHours > 0) {
    return `${diffInHours} hour${diffInHours !== 1 ? "s" : ""} ago`;
  } else {
    return `${diffInMinutes} min${diffInMinutes !== 1 ? "s" : ""} ago`;
  }
};
export const timeCreatedOn = (savedTime) => {
  if (savedTime) {
    const date = new Date(savedTime);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  } else {
    return "_";
  }
};
export const textFormat = (text) => {
  const formattedText = text?.replace(/\s+/g, " ").trim();
  return formattedText;
};
export const validateInput = (rule, value) => {
  if (!value) {
    return Promise.resolve();
  } else if (value.trim() === "") {
    return Promise.reject(rule);
  } else {
    return Promise.resolve();
  }
};

export const isLinkValid = (rule, inputValue) => {
  const urlPattern = /^(http(?:s)?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  if (!inputValue) {
    return Promise.resolve();
  } else if (!urlPattern.test(inputValue)) {
    return Promise.reject(rule);
  } else {
    return Promise.resolve();
  }
};

export const scriptStages = [
  "SCRIPT_GENERATION_IN_PROGRESS",
  "AUDIO_GENERATION_INPROGRESS",
  "AUDIO_GENERATION_COMPLETED",
  "VIDEO_GENERATION_STARTED",
  "VIDEO_GENERATION_COMPLETED",
];
export const videoStages = [
  "VIDEO_GENERATION_STARTED",
  "VIDEO_GENERATION_COMPLETED",
];

export const audioStages = [
  "LESSON_SCRIPT_COMPLETED",
  "AUDIO_GENERATION_COMPLETED",
  "VIDEO_GENERATION_STARTED",
  "VIDEO_GENERATION_COMPLETED",
];

export const audioSection = [
  "AUDIO_GENERATION_INPROGRESS",
  "AUDIO_GENERATION_COMPLETED",
];
export const scriptUpdateStages = [
  "AUDIO_GENERATION_INPROGRESS",
  "AUDIO_GENERATION_COMPLETED",
  "VIDEO_GENERATION_STARTED",
  "VIDEO_GENERATION_COMPLETED",
];
export const videoTypes = [
  { label: "Training", value: "TRAINING" },
  { label: "Personalized", value: "PERSONALIZED" },
  { label: "DIY", value: "DIY" },
  { label: "Template Based Training Video", value: "TEMPLATE_BASED_TRAINING_VIDEO" },
];

export function capitalizeInput(input) {
  const words = input.split(/\s+/);
  const capitalizedWords = words.map((word, index) => {
    if (index === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    } else {
      return word.toLowerCase();
    }
  });
  return capitalizedWords.join("");
}

export const getStatusLabel = (status) => {
  const statusMap = {
    CREATED: "Created",
    CONTENT_BRIEFING_GENERATED: "Content Briefing Generated",
    COURSE_BRIEFING_CONFIRMED: "Course Briefing Confirmed",
    CONTENT_BRIEFING_FAILED: "Content Briefing Failed",
    LESSON_GENERATION_FAILED: "Lesson Generation Failed",
    LESSON_GENERATION_SUCCESS: "Lesson Generation Success",
    LESSON_SCRIPT_COMPLETED: "Lesson Script Completed",
    LESSON_SCRIPT_SUBMITTED: "Lesson Script Submitted",
    LESSON_CREATION_INPROGRESS: "Lesson Creation In Progress",
    AUDIO_GENERATION_INPROGRESS: "Audio Generation In Progress",
    AUDIO_GENERATION_COMPLETED: "Audio Generation Completed",
    VIDEO_GENERATION_STARTED: "Video Generation Started",
    VIDEO_GENERATION_COMPLETED: "Video Generation Completed",
  };

  // Check if status exists in the mapping, else return "Created"
  return statusMap[status] || "Created";
};
