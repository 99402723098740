import { Button, Flex } from "antd";
import React, { useRef, useState } from "react";
import { ReactMediaRecorder } from "react-media-recorder";
import "./avatars.css";
import ActionButtons from "./actionButtons";

const VideoRecorder = ({ setVideo, setIsRecorderON }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [blobUrl, setBlobUrl] = useState(null);
  const stopFunctionRef = useRef(null);

  const handleSave = async (blobUrl, mediaBlobUrl) => {
    if (!blobUrl) return;

    try {
      const response = await fetch(blobUrl); // Convert blob URL to a blob
      const blob = await response.blob();
      const file = new File([blob], "recorded-video.mp4", { type: blob.type }); // Convert blob to file
      setVideo({ data: file, type: "record", url: mediaBlobUrl });
      setIsRecorderON(false);
    } catch (error) {
      console.error("Error uploading video:", error);
    }
  };

  return (
    <div>
      <ReactMediaRecorder
        video
        onStop={(blobUrl) => setBlobUrl(blobUrl)}
        render={({
          startRecording,
          stopRecording,
          mediaBlobUrl,
          previewStream,
          clearBlobUrl,
        }) => {
          stopFunctionRef.current = stopRecording; // Save stopRecording to ref

          return (
            <Flex vertical gap={10}>
              {/* Live Video Preview */}
              {previewStream?.active && !mediaBlobUrl ? (
                <div>
                  <video
                    style={{ width: "100%", maxHeight: "400px" }}
                    ref={(videoElement) => {
                      if (videoElement) {
                        videoElement.srcObject = previewStream;
                      }
                    }}
                    autoPlay
                    muted
                  />
                </div>
              ) : (
                !mediaBlobUrl && (
                  <Flex
                    align="center"
                    justify="center"
                    className="video-placeholder"
                  >
                    <span>
                      {" "}
                      Click <b> Start Recording </b> to start recording the
                      video
                    </span>
                  </Flex>
                )
              )}

              {mediaBlobUrl && (
                <div style={{ width: "100%", maxHeight: "400px" }}>
                  <video
                    style={{ width: "100%", maxHeight: "400px" }}
                    src={mediaBlobUrl}
                    controls
                    autoPlay
                    loop
                  />
                </div>
              )}

              {!mediaBlobUrl && !blobUrl ? (
                <ActionButtons
                  setIsRecording={setIsRecording}
                  startRecording={startRecording}
                  stopFunctionRef={stopFunctionRef}
                  isRecording={isRecording}
                  stopRecording={stopRecording}
                  setIsRecorderON={setIsRecorderON}
                  clearBlobUrl={clearBlobUrl}
                />
              ) : (
                <Flex gap={10}>
                  <Button
                    onClick={() => {
                      stopRecording();
                      setIsRecording(false);
                      clearBlobUrl();
                      setIsRecorderON(false);
                    }}
                  >
                    Back{" "}
                  </Button>
                  <Button
                    onClick={() => {
                      stopRecording();
                      setIsRecording(false);
                      clearBlobUrl();
                      mediaBlobUrl = null;
                      setBlobUrl();
                    }}
                  >
                    Record another
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      handleSave(blobUrl, mediaBlobUrl);
                    }}
                  >
                    Save{" "}
                  </Button>
                </Flex>
              )}
            </Flex>
          );
        }}
      />
    </div>
  );
};

export default VideoRecorder;
