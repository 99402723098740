import axios from "axios";
import { getToken, logout } from "../services/storageService";
import NotificationInstance from "../services/notificationServices";
import { notification } from "antd";

const authErrors = [
  "The link has expired",
  "Invalid token",
  "Resource does not exist",
];

const AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER_ADDRESS}/api`,
  timeout: 10 * 60 * 1000,
  headers: {
    "api-key": process.env.REACT_APP_API_KEY,
    "ngrok-skip-browser-warning": "1",
  },
});
AxiosInstance.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    throw error;
  }
);

AxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    if (
      error?.response?.status === 401 &&
      !authErrors.includes(error?.response?.data?.message)
    ) {
      // logout();
    } else if (
      error?.response?.status === 401 &&
      error?.response?.data?.message === "Resource does not exist"
    ) {
      NotificationInstance.error({
        message: "Access Denied",
        description:
          "You do not have the necessary permissions to edit this resource.",
      });
    } else {
      throw error;
    }
  }
);

const AxiosInstance1 = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER_ADDRESS}/api/admin`,

  headers: {
    "api-key": process.env.REACT_APP_API_KEY,
    "ngrok-skip-browser-warning": "1",
  },
});

AxiosInstance1.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    throw error;
  }
);

AxiosInstance1.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);

    if (error?.response?.status === 401) {
      if (
        !window.location.href.includes("demo") &&
        !authErrors.includes(error?.response?.data?.message)
      ) {
        // logout();
        NotificationInstance.error({
          message: "Error",
          description: "Invalid User , Please login again.",
        });
      } else if (error?.response?.data?.message === "Resource does not exist") {
        NotificationInstance.error({
          message: "Access Denied",
          description:
            "You do not have the necessary permissions to edit this resource.",
        });
      } else {
        NotificationInstance.error({
          message: "Invalid reference token",
        });
      }
    } else {
      throw error;
    }
  }
);
export default AxiosInstance;

export { AxiosInstance1 };
