import { Avatar, Button, Flex, Form, Input, Modal, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";
import { createVideoUseCase } from "../../api/adminService";
import NotificationInstance from "../../services/notificationServices";
import { videoTypes } from "../../utils/format";
import { getContacts } from "../../api/hubSpotService";
import { getAllProjects } from "../../api/projectServices";

const customOption = (option) => {
  return (
    <Flex gap={10} align="center">
      <Avatar
        shape={"circle"}
        src={`https://ui-avatars.com/api/?name=${option.data.label}&background=101A24&color=fff`}
        size={30}
      />
      <Flex vertical>
        <b>{option.data.label}</b>
        <span role="img" aria-label={option.data.label}>
          {option.data.email}
        </span>
      </Flex>
    </Flex>
  );
};

const CreateVideoModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  videoType = "Video",
  project,
}) => {
  const [leads, setLeads] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedLead, setSelectedLead] = useState();
  const [total, setTotal] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [currentProjectPage, setcurrentProjectPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [initial, setinitial] = useState(true);
  const [type, setType] = useState();
  const [disable, setDisable] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const [form] = Form.useForm();

  const onSubmit = () => {
    form
      .validateFields()
      .then((val) => {
        if (project) {
          val = {
            ...val,
            projectId: project,
            type: "TEMPLATE_BASED_TRAINING_VIDEO",
          };
        }
        setDisable(true);
        createVideoUseCase(val)
          .then((res) => {
            setDisable(false);
            form.resetFields();
            handleOk(res?.data?.useCaseId, type);
            NotificationInstance.success({
              message: "Successful",
              description: "Successfully created new video",
            });
          })
          .catch((err) => {
            console.log(err);
            setDisable(false);
            NotificationInstance.error({
              message: "Video creation failed",
              description: "Please try again.",
            });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onTypeChange = (value) => {
    setType(value);
    setSelectedLead();
    setSelectedProject();
    form.setFieldsValue({ leadsId: null, projectId: null });
  };

  const onModalClose = () => {
    setType();
    form.resetFields();
    handleCancel();
  };

  const fetchProjects = () => {
    getAllProjects("", "", "", 20, currentProjectPage)
      .then((res) => {
        const responseData = res?.data.map((item) => ({
          label: item.title,
          value: item._id,
        }));
        if (initial) {
          setProjects(responseData);
        } else {
          setProjects((prevCategories) => [...prevCategories, ...responseData]);
        }
        setTotal(res?.info?.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
    setinitial(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchProjects();
  }, [currentProjectPage]);

  const fetchLeads = () => {
    getContacts("", "", "", currentPage)
      .then((res) => {
        const responseData = res?.data.map((item) => ({
          label: `${item.firstname} ${item.lastname}`,
          value: item._id,
          email: item.email,
        }));
        if (initial) {
          setLeads(responseData);
        } else {
          setLeads((prevCategories) => [...prevCategories, ...responseData]);
        }
        setTotal(res?.info?.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
    setinitial(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchLeads();
  }, [currentPage]);

  const onscroll = (e) => {
    const { target } = e;
    const bottom =
      target.scrollHeight - target.scrollTop === target.clientHeight;
    if (bottom && leads?.length < total) {
      setcurrentPage((prev) => prev + 1);
    }
  };
  const onProjectScroll = (e) => {
    const { target } = e;
    const bottom =
      target.scrollHeight - target.scrollTop === target.clientHeight;
    if (bottom && leads?.length < total) {
      setcurrentProjectPage((prev) => prev + 1);
    }
  };

  return (
    <Modal
      centered
      width={500}
      title="Create New Video"
      open={isModalOpen}
      onCancel={onModalClose}
      footer={() => (
        <div className="action-buttons">
          <Button
            type="primary"
            onClick={onSubmit}
            disabled={disable}
            loading={disable}
          >
            Create Video
          </Button>
          <Button className="cancel-button" onClick={onModalClose}>
            Cancel
          </Button>
        </div>
      )}
    >
      <div className="title-input-wrapper">
        <Form
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          className="add-user"
        >
          <Form.Item
            name="title"
            label="Title"
            required={false}
            rules={[
              {
                required: true,
                message: "Please enter title",
                validateTrigger: onsubmit,
              },
            ]}
          >
            <Input placeholder="Enter the title" />
          </Form.Item>
          {videoType === "Video" && (
            <Form.Item
              name="type"
              label="Video Type"
              required={false}
              rules={[
                {
                  required: true,
                  message: "Please select a video type",
                  validateTrigger: onsubmit,
                },
              ]}
            >
              <Select
                value={type}
                placeholder="Select video type"
                optionFilterProp="children"
                onSelect={onTypeChange}
                variant="borderless"
                popupMatchSelectWidth={true}
                allowClear
                popupClassName="sort-options"
                options={videoTypes}
                onClear={() => setType()}
              />
            </Form.Item>
          )}
          {type === "PERSONALIZED" && (
            <Form.Item
              name="leadsId"
              label="Lead Name"
              required={false}
              rules={[
                {
                  required: true,
                  message: "Please select a lead",
                  validateTrigger: onsubmit,
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a Lead"
                optionFilterProp="label"
                variant="borderless"
                popupMatchSelectWidth={true}
                allowClear
                popupClassName="sort-options"
                options={leads}
                onPopupScroll={onscroll}
                value={selectedLead}
                loading={loading}
                optionRender={(option) => customOption(option)}
                onClear={() => setSelectedLead()}
                onSelect={(val) => setSelectedLead(val)}
              />
            </Form.Item>
          )}
          {type === "TEMPLATE_BASED_TRAINING_VIDEO" && (
            <Form.Item name="projectId" label="Project Name" required={false}>
              <Select
                showSearch
                placeholder="Select a Project"
                optionFilterProp="label"
                variant="borderless"
                popupMatchSelectWidth={true}
                allowClear
                popupClassName="sort-options"
                options={projects}
                onPopupScroll={onProjectScroll}
                value={selectedProject}
                loading={loading}
                onSelect={(val) => setSelectedProject(val)}
                onClear={() => setSelectedProject()}
              />
            </Form.Item>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default CreateVideoModal;
