import { Button, Checkbox, Form, Modal, Popconfirm, Slider } from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";

const VoiceSettings = ({
  isModalOpen,
  handleOk,
  handleCancel,
  updateItem,
  setVoiceSettings,
}) => {
  const [form] = Form.useForm();
  const [isUpdated, setIsUpdated] = useState(false);

  const defaultSettings = {
    stability: "0.35",
    similarity_boost: "0.75",
    style: "0",
    use_speaker_boost: "true",
  };

  useEffect(() => {
    if (updateItem && updateItem.settings) {
      form.setFieldsValue(updateItem.settings);
    } else if (updateItem && !updateItem.settings) {
      form.setFieldsValue(defaultSettings);
    }
  }, [updateItem]);

  const onSubmit = () => {
    form
      .validateFields()
      .then((val) => {
        console.log(val);
        setVoiceSettings(val);
        form.resetFields();
        handleOk(updateItem.item, val);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onModalClose = () => {
    form.resetFields();
    handleCancel();
  };

  const marks = {
    0: 0.0,
    1: 1.0,
  };
  const Speedmarks = {
    0.75: " 0.75x",
    1.5: "1.5x",
  };
  return (
    <Modal
      centered
      width={500}
      title="Voice Settings"
      open={isModalOpen}
      closable={false}
      footer={() => (
        <div className="action-buttons">
          <Popconfirm
            overlayStyle={{ width: 400 }}
            title={
              updateItem?.item === "all"
                ? "Are you sure you want to regenerate all audios with the new settings?"
                : "Are you sure you want to regenerate the selected audio with the new settings?"
            }
            onConfirm={onSubmit}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" disabled={!isUpdated}>
              Apply
            </Button>
          </Popconfirm>
          <Button className="cancel-button" onClick={onModalClose}>
            Cancel
          </Button>
        </div>
      )}
    >
      <div className="title-input-wrapper">
        <h4>
          {updateItem?.item === "all"
            ? "This will update the voice settings for all audios."
            : "This will update the voice settings for the selected audio."}
        </h4>
        <Form
          form={form}
          onFieldsChange={() => setIsUpdated(true)}
          layout="vertical"
          className="add-user"
        >
          <Form.Item
            name="stability"
            required={false}
            label="Stability"
            rules={[
              {
                required: true,
                message: "Please set stability",
              },
            ]}
            initialValue={0}
          >
            <Slider marks={marks} min={0} max={1} step={0.01} />
          </Form.Item>

          <Form.Item
            name="similarity_boost"
            required={false}
            label="Similarity Boost"
            initialValue={0}
          >
            <Slider marks={marks} min={0} max={1} step={0.01} />
          </Form.Item>

          <Form.Item
            name="style"
            required={false}
            label="Style"
            rules={[
              {
                required: true,
                message: "Please set style",
              },
            ]}
            initialValue={0.0}
          >
            <Slider marks={marks} min={0} max={1} step={0.01} />
          </Form.Item>
          <Form.Item
            name="playback_speed"
            required={false}
            label="Playback Speed"
            initialValue={1.0}
          >
            <Slider marks={Speedmarks} min={0.75} max={1.5} step={0.05} />
          </Form.Item>

          <Form.Item
            name="use_speaker_boost"
            required={false}
            valuePropName="checked"
            initialValue={true}
          >
            <Checkbox>Use Speaker Boost</Checkbox>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default VoiceSettings;
