import AxiosInstance from "./axiosInstance";

export const getVideoChatMessage = async (
  channel,
  useCaseId,
  thread,
  limit,
  pageNo
) => {
  let query = "?";
  if (thread) {
    query = query + `threadCtxId=${thread}&`;
  }
  if (limit) {
    query = query + `limit=${limit}&`;
  }
  if (pageNo > 0) {
    query = query + `pageNo=${pageNo}&`;
  }
  try {
    const response = await AxiosInstance.get(
      `/admin/use-case/${useCaseId}/channel/${channel}` + query
    );
    if (response?.data?.data) {
      return response.data;
    } else {
      throw new Error("chat listing failed");
    }
  } catch (error) {
    throw error;
  }
};

export const updateVideoChatMessage = async ({ useCaseId, channel, data }) => {
  try {
    const response = await AxiosInstance.post(
      `/admin/use-case/${useCaseId}/channel/${channel}`,
      data
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("script submission failed");
    }
  } catch (error) {
    throw error;
  }
};
